import { userService } from '../services';
import {
  POST_MEDICAL_ID,
  POST_CARD_ID,
  GET_MEDICAL_ID,
  GET_CARD_ID,
  POST_MEDICAL_ID_REQUEST,
  POST_MEDICAL_ID_FAIL,
  POST_CARD_ID_FAIL,
  POST_CARD_ID_REQUEST,
  GET_MEDICAL_ID_REQUEST,
  GET_MEDICAL_ID_FAIL, GET_CARD_ID_REQUEST, GET_CARD_ID_FAIL
} from '../config/actionTypes';

export const documentsActions = {
    medicalIdUpload,
    cardIdUpload,
    medicalIdGet,
    cardIdGet,
    removeMedicalImage,
    removeIdImage,
    emptyMedicalPostId,
    emptyMedicalGetId,
    emptyCardPostId,
    emptyCardGetId,
};

function medicalIdUpload(data) {
    return dispatch => {
        dispatch(requestLoader(null))
        userService.PostFile('/attachments/medical', data)
          .then(
            data => dispatch(success(data))
          ).catch(
            err => {
              dispatch(requestFail(err))
            }
          );
    };
    function requestLoader(medicalId) { return { type: POST_MEDICAL_ID_REQUEST, medicalId, loading: true } }
    function requestFail(err) { return { type: POST_MEDICAL_ID_FAIL, err, loading: false } }
    function success(medicalId) { return { type: POST_MEDICAL_ID, medicalId, loading: false } }
}

function cardIdUpload(data) {
    return dispatch => {
        dispatch(requestLoader(null))
        userService.PostFile('/attachments/id-card', data)
          .then(
            data => dispatch(success(data))
          ).catch(
            err => {
              dispatch(requestFail(err))
            }
        );
    };
  function requestLoader(cardId) { return { type: POST_CARD_ID_REQUEST, cardId, loading: true } }
  function requestFail(err) { return { type: POST_CARD_ID_FAIL, err, loading: true } }
  function success(cardId) { return { type: POST_CARD_ID, cardId, loading: true } }
}

function medicalIdGet() {
    return dispatch => {
        dispatch(requestLoader(null))
        userService.GetInfo('/attachments/medical', '')
          .then(
            data => dispatch(success(data))
          ).catch(
            err => {
              dispatch(requestFail(err))
            }
        );
    };
    function requestLoader(medicalId) { return { type: GET_MEDICAL_ID_REQUEST, medicalId, loading: true } }
    function requestFail(err) { return { type: GET_MEDICAL_ID_FAIL, err, loading: false } }
    function success(medicalId) { return { type: GET_MEDICAL_ID, medicalId, loading: false } }
}

function cardIdGet() {
    return dispatch => {
      dispatch(requestLoader(null))
        userService.GetInfo('/attachments/id-card', '')
          .then(
            data => dispatch(success(data))
          ).catch(
            err => {
              dispatch(requestFail(err))
            }
        );
    };
    function requestLoader(cardId) { return { type: GET_CARD_ID_REQUEST, cardId, loading: true } }
    function requestFail(err) { return { type: GET_CARD_ID_FAIL, err, loading: false } }
    function success(cardId) {
        return { type: GET_CARD_ID, cardId, loading: false}
    }
}
function removeMedicalImage() {
   return  userService.DeleteInfo('/attachments/medical', '')
}
function removeIdImage() {
   return  userService.DeleteInfo('/attachments/id-card', '')
}

function emptyCardPostId() { return { type: POST_CARD_ID, cardId: '' } }
function emptyCardGetId() { return { type: GET_CARD_ID, cardId: '' } }

function emptyMedicalPostId() { return { type: POST_MEDICAL_ID, medicalId: '' } }
function emptyMedicalGetId() { return { type: GET_MEDICAL_ID, medicalId: '' } }
