import { darken, lighten, rgba } from "polished";
import { colors, sizes } from "./_baseTheme";

const primary = "#3abf7c";
const secondary = "#FF8686";
const tertiary = "#171c25";

const flowerTheme = {
    colors: {
        ...colors,
        brandDark: colors.flowerBrandDark,
        brandLight: colors.flowerBrandLight,
        promoBannerBackground: colors.flowerBrandLight,
        highlight: colors.flowerBrandBright,
        error: colors.flowerWarning,
        warning: colors.flowerWarning,
        grey2: colors.flowerGray2,
        grey3: colors.flowerGray3,
        accentHybrid: colors.flowerSale,
        brand: colors.flowerBrandConst,
        onlyLeftRed: colors.flowerWarning,
        saleGreen: colors.flowerSale,

        primary: {
            normal: primary,
            hover: lighten(0.1, primary),
            active: darken(0.1, primary),
            outline: {
                background: rgba(primary, 0.04)
            }
        },
        secondary: {
            normal: secondary,
            hover: lighten(0.1, secondary),
            active: darken(0.1, secondary),
            outline: {
                background: rgba(secondary, 0.04)
            }
        },
        tertiary: {
            normal: tertiary,
            hover: lighten(0.1, tertiary),
            active: darken(0.1, tertiary),
            outline: {
                background: rgba(tertiary, 0.04)
            }
        },
        footerBackground: tertiary,
        shopMobileFilterForm: darken(0.03, tertiary),
    },
    ...sizes
};

export default flowerTheme;
