import {
    BRAND_LOADING,
    GET_BRAND_PRODUCTS,
    LOAD_MORE_BRANDS
} from '../config/actionTypes';


export function brandProducts(state = {}, action) {
    switch (action.type) {
        case GET_BRAND_PRODUCTS:
            return {
                data: action.result,
                loading: action.loading
            };
        case BRAND_LOADING:
            return {
                data: action.result,
                loading: action.loading
            };
        case LOAD_MORE_BRANDS:
            return {
                data: {
                    ...state.data,
                    next: action.result.next,
                    list: state.data.list.concat(action.result.list)
                },
            };
        default:
            return state
    }
}

