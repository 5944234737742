import {userService} from "../services";
import { GET_YOTPO_IDS, GET_YOTPO_IDS_FAIL, GET_YOTPO_IDS_REQUEST } from "../config/actionTypes";

export const yotpoData = {
    getYotpoData
};

function getYotpoData() {
    return dispatch => {
        dispatch(requestLoader(null));
        userService.GetInfo('/yotpo/divs', '')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
            err => {
                dispatch(requestFail(err));
            }
        );
    };
    function requestLoader(data) { return { type: GET_YOTPO_IDS_REQUEST, data, loading: true } }
    function requestFail(err) { return { type: GET_YOTPO_IDS_FAIL, err, loading: false } }
    function success(data) { return { type: GET_YOTPO_IDS, data, loading: false } }
}