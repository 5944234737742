import styled from "styled-components";

const StyledInputSearchAhead = styled.div`
  --input-button-width: 38px;
  --max-width: 1200px;

  // --- Icon with trigger to open full screen modal ---
  .input-search__icon {
     flex-shrink: 0;
     display: inline-flex;
     background-color: ${({theme}) => theme.colors.background};
     fill: ${({theme}) => theme.colors.brandDark};
     outline: none;

     &:hover,
     &:focus {
        fill: ${({ theme }) => theme.colors.brand};
     }

     svg {
      padding: 8px;
      width: 22px;
      height: 22px;
      box-sizing: content-box;
      fill: inherit;
    }
  }

  // --- Wrapper for fullscreen modal ---
  .input-search-ahead {
    height: 100%;
    width: 100vw;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: opacity ease .5s;
    transform : translateY(-100vh);
    opacity : 0;
    background-color: ${({ theme }) => theme.colors.white};
    z-index: 10;

    &.is-active {
      transform : translateY(0);
      opacity : 1;
      z-index: 10;
      overflow: initial;
    }

    @media (max-width: 859px) {
      padding: 1.5rem 16px 0;
    }
    @media (min-width: 860px) {
      padding: 2.625rem 72px 0;
    }
  }

  .input-search-ahead__wrap {
    margin-right: auto;
    margin-left: auto;
    display: flex;
    width: 100%;
    max-width: var(--max-width);
  }

  .input-search-ahead__close-modal {
    margin-left: auto;
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.colors.brandDark};
    outline: none;
    transition: var(--button-transition);

    &:hover,
    &:focus {
      fill: ${({ theme }) => theme.colors.brand}
    }
  }
  
    // --- Styling search form ---
  .input-search-ahead__form {
    margin-top: 1rem;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    width: 100%;
    max-width: var(--max-width);
    border-bottom: solid 1px ${({ theme }) => theme.colors.gray4};
    position: relative;

    @media (max-width: 859px) {
      padding-bottom: .25rem;
    }
    @media (min-width: 860px) {
      padding-bottom: .5rem;
    }
  }

  .input-search-ahead__form-input {
    margin: 0; /* Needed for safari */
    flex-grow: 1;
    letter-spacing: 1px;
    max-width: calc(100% - var(--input-button-width));
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    outline: none;
    
    @media (max-width: 859px) {
      padding: .625rem 16px .625rem 8px;
    }
    @media (min-width: 860px) {
      padding: .625rem 16px;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray2};
      font-size: var(--type-scale-18);
    }
  }

  .input-search-ahead__search-button {
    flex-shrink: 0;
    display: inline-block;
    fill: ${({theme}) => theme.colors.white};
    background-color: transparent;
    border: none;
    fill: ${({theme}) => theme.colors.brandDark};
    transition: var(--button-transition);
    outline: none;

    svg {
      width: 22px;
      height: 22px;
      box-sizing: content-box;
      fill: inherit;
    }

    @media (max-width: 859px) {
      svg {
        padding: 8px 8px 8px 0;
      }
    }
    @media (min-width: 860px) {
      svg {
        padding: 8px;
      }
    }
    
    &:hover,
    &:focus {
       fill: ${({ theme }) => theme.colors.brand};
    }

  }
  
  .input-search-ahead__clear-search {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    svg { 
      fill: ${({theme}) => theme.colors.gray3};
    }
  }

  // --- Styling when results are found ---
  .input-search-ahead__results {
    margin-right: auto;
    margin-left: auto;
    padding-top: 2rem;
    max-width: var(--max-width);
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
  }

  .input-search-ahead__results__header {
    padding: 0 8px .625rem;
    display: inline-flex;
    flex-direction: row;
    align-items: baseline;
  }

  .input-search-ahead__results__heading {
    color: ${({ theme }) => theme.colors.brandDark};
  }

  .input-search-ahead__view-all {
    color: ${({ theme }) => theme.colors.gray3};
    margin-left: auto;
    transition: var(--button-transition);
    outline: none;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.brandDark};
    }
  }

  .input-search-ahead__results__list {
    overflow: auto;
  }

  .input-search-ahead__results__list-item {
    &:not(::last-of-type) {
      margin-bottom: .25rem;
    }
  }
  
  .input-search-ahead__results__item {
    transition: var(--button-transition);
    padding: 10px 8px;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray4};
    display: block;
  
    &:hover,
    &focus {
        background-color: ${({ theme }) => theme.colors.gray1};
    }
  }

  // --- Styling when no results are found ---
  .input-search-ahead__no-result {
    margin-right: auto;
    margin-left: auto;
    padding-top: 4rem;
    max-width: var(--max-width);
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  .input-search-ahead__no-result__heading {
    text-align: center;
  }

  .input-search-ahead__search-all {
    margin-top: 2rem;
    max-width: 280px;
    letter-spacing: 1px;
  }
`;
export default StyledInputSearchAhead;
