import styled from "styled-components";

const StyledHeaderSimpleEverscore = styled.header`
  padding-bottom: 10px;
  padding-top: 10px;
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({theme}) => theme.colors.gray2};
  background-color: ${({theme}) => theme.colors.white};

  .header-simple__link {
    display: inline-flex;
  }
  
  .header-simple__logo {
    display: inline-flex;
    height: auto;

    @media (max-width: 859px) {
      width: 140px;
      height: 60px;
    }
    @media (min-width: 860px) {
        width: 170px;
        height: 45px;
    }
  }

  .header-simple__logo-other {
    display: inline-flex;
    height: auto;

    @media (max-width: 859px) {
      max-height: 28px;
    }
    @media (min-width: 860px) {
      max-height: 38px;
    }
  }

`;
export default StyledHeaderSimpleEverscore;
