import styled from "styled-components";

const StyledMainMenuEverscore = styled.nav`
  padding: 2rem 0 2rem 38px;
  width: 100%;
  display: block;
  position: absolute;
  top: 81px;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 9px 15px 0 rgba(0,0,0,0.1);
  z-index: 1;

  @media (max-width: 859px) {
    display: none;
  }

  &.is-hidden {
    position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }

  .main_menu {
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-gap: 20px 40px;
    
    @media (min-width: 860px) and (max-width: 1279px) {
      grid-template-columns: repeat(3, 1fr);
      max-width: 680px;
    }
    @media (min-width: 1280px) {
      grid-template-columns: repeat(5, minmax(166px, auto));
    }
  }

  .main-menu__brands {
    grid-column: span 3;
  }

  .main_menu__list-grid {
    display: grid;
    grid-gap: 0 40px;
    grid-template-columns: repeat(3, minmax(166px, 1fr));
    padding-top: 1rem;
  }

  .main_menu__list {
    display: flex;
    flex-direction: column;
    grid-gap: 0 40px;
    padding-top: 1rem;
  }
  
  .main-menu__discover{
    margin-top: 1rem;
  }

  .main_menu__heading {
    margin-bottom: .75rem;
    width: 100%;
    display: block;
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 24px;
    line-height: 28.8px;
    font-weight: bold;
  }

  .main_menu__heading__brands {
    margin-bottom: .75rem;
    width: 100%;
    display: block;
    color: ${({ theme }) => theme.colors.gray4};
    font-size: 24px;
    line-height: 28.8px;
    font-weight: bold;
  }

  .main-menu-link {
    padding-right: 32px;
    width: fit-content;
    color: ${({ theme }) => theme.colors.brandDark};
    text-decoration: none;
    transition: var(--button-transition);
    display: inline-flex;
    align-items: center;
    position: relative;
    flex-shrink: 0; // Needed for Safari //
    white-space: nowrap; // Needed for Safari //

    svg {
      position: absolute;
      right: -12px;
      top: -3px;
      width: 32px;
      height: 30px;
    }

    &:hover,
    &:focus {
      svg {
        right: -16px;
      }
    }
  }

  .main-menu-link__shop {
    font-size: 24px;
    font-weight: bold;
    font-family: var(--font-NHaasGroteskDSPro-65Md);
    color: #222;
    line-height: 28.8px;
  }

  .main-menu-link__brands {
    @media (min-width: 860px) and (max-width: 1279px) {
      grid-area: 3/3;
    }
    @media (min-width: 1280px) {
      grid-area: 2/6;
    }
  }
`;
export default StyledMainMenuEverscore;
