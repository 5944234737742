import React from "react";
import HeaderDeliveryInputBundle from "../HeaderDeliveryInputBundle/HeaderDeliveryInputBundle";
import StyledHeaderMainSecondaryBundle from "./HeaderMainSecondaryBundle.styled";

const HeaderMainSecondaryBundle = React.memo(() => {
  return (
    <StyledHeaderMainSecondaryBundle>
      <div className="header-main__wrap">
        <HeaderDeliveryInputBundle />
      </div>
    </StyledHeaderMainSecondaryBundle>
  );
});

export default HeaderMainSecondaryBundle;