import styled from "styled-components";

const StyledModalVerificationBundle = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform : ${props => props.isShown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity : ${props => props.isShown ? '1' : '0'};
  z-index: 10;
  filter: blur(0);

  .verification {
    margin: 10% 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 250px;
    background-color: ${({theme}) => theme.colors.bundleBlack};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 12;
    border: 3px solid ${({theme}) => theme.colors.bundleWhite};
    border-radius: 25px;
    
    @media (max-width: 799px) {
      padding: 0.5rem 16px 1.8125rem;
      max-width: 379px;
    }
    @media (min-width: 800px) {
      padding: 0.5rem 16px 2.625rem;
      max-width: 710px;
    }
  }
  
  .verification__logo {
    align-self: center;
    
    @media (max-width: 799px) {
      margin-bottom: 2rem;
      width: 80px;
      height: 60px;
    }
    @media (min-width: 800px) {
      margin-bottom: 2rem;
      width: 150px;
      height: 100px;
    }
  }
  
  .verification__age {
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    
    &.is-disabled {
      color: ${({theme}) => theme.colors.bundleGrey};
    }
  }
  
  .verification__button,
  .verification__age__button {
    @media (max-width: 799px) {
      width: 141px;
      min-height: 40px;
    }
    @media (min-width: 800px) {
      width: 180px;
      min-height: 58px;
    }
  }

  // Button / Link overwrite, change with future button and link design cleanup
  .verification__button {
    border: 3px solid ${({ theme }) => theme.colors.bundleWhite};
    border-radius: 29px;
    opacity: 1;
    background-color: ${({ theme }) => theme.colors.bundleBlack};
    color: ${({ theme }) => theme.colors.bundleWhite};
    padding: 16px 31px;
    line-height: normal;
    display: inline-block;
    font-weight: var(--weight-bold);
    text-align: center;
    flex-shrink: 0;
    transition: var(--button-transition);
    text-decoration: none;
    margin-left: 20px;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.bundleWhite};
      border-color: ${({ theme }) => theme.colors.bundleWhite};
      color: ${({ theme }) => theme.colors.bundleBlack};
    }
    @media (max-width: 799px) {
      margin-top: .5rem;
      padding: 14px 31px;
    }
    &.is-disabled {
      background-color: ${({ theme }) => theme.colors.bundleBlack};
      color: ${({ theme }) => theme.colors.bundleWhite};
      cursor: default;
    }
  }
  
  .verification__input {
    background-color: ${({theme}) => theme.colors.background};
    color: ${({theme}) => theme.colors.brandDark};
    outline: none;
    border: none;
    border-radius: 0;
    border-bottom: 3px solid ${({theme}) => theme.colors.bundleWhite};
    
     &:focus {      
      border-color: ${({theme}) => theme.colors.bundleWhite};
    }
    
    @media (max-width: 859px) {
      padding: 0 28px 3px 2px;
      width: 90%;
      height: 24px;
      font-size: 11px;
    }

    @media (min-width: 860px) {
      padding: 20px 22px 0 2px;
      width: 513px;
      height: 50px;
      border-radius: 0;
      font-size: 15px;
    }
  }

  .verification__form {
    display: flex;
    flex-direction: column;
    max-width: 512px;
    width: 100%;

    @media (max-width: 799px) {
      margin-bottom: 2.5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 3.5rem;
    }
  }

  .verification__form__label {
    width: 100%;  
    position: relative;
    color: ${({theme}) => theme.colors.brandDark};
    margin: auto;
    
    @media (max-width: 799px) {
      padding-left: 17px;
    }
  }
  
  .verification__form__age-heading {
    margin-bottom: .75rem;
    color: inherit;

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: .75rem;
    }
  }
  
  .verification__form__label-text {
    
    @media (max-width: 799px) {
      margin-bottom: 1rem;
      font-size: 17px;
    }
    @media (min-width: 800px) {
      font-size: 25px;
    }
  }

  .verification__form__age-copy {
    color: inherit;

    @media (max-width: 799px) {
      margin-bottom: 1rem;
      font-size: 17px;
      padding: 0 43px;
    }
    @media (min-width: 800px) {
      margin-bottom: 2rem;
      font-size: 25px;
    }
  }

  .verification__footnote {
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.brandDark};

    .t-link {
      color: inherit;
    }
    .t-link:hover,
    .t-link:focus {
      color: ${({theme}) => theme.colors.brand};
    }
  }
  .not_deliv{
    margin: 3px 0;
    font-size: 12px;
  }

  /* Temporary until v2 design */

  .verification__form__address-input {
    position: relative;
    @media (max-width: 799px) {
      padding: 0 12px;
    }
  }
  .verification__form__ip-address-input {
    margin-top: 15px;
    .placeHold{
      display: none;
    }
    .verification__form__label-text{
      color: #183f31;
    }
    input{
      border-radius: 0;
    }
  }

  .verification-delivery_address__clear {
    
    position: absolute;
    right: -3px;
    top: 15px;
    @media (max-width: 799px) {
      top: 4px;
      right: 40px;

      svg {
        fill: ${({theme}) =>  theme.colors.bundleGrey3 };
        width: 16px;
        height: 16px;
      }
    }
    @media (min-width: 800px) {
      width: 26px;
      height: 26px;
      margin-bottom: 1.25rem;
      font-size: 25px;
      svg {
        fill: ${({theme}) =>  theme.colors.bundleWhite };
        width: inherit;
        height: inherit;
      }
    }


    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
  }
`;

export default StyledModalVerificationBundle;
