import {
    ADD_CART,
    ADD_CART_LOADING,
    ADD_CART_FAIL,
    GET_SIMILAR_PRODUCTS,
    GET_PRODUCT_DETAILS,
    CHANGE_PRODUCT_DETAILS,
    FAILED_PRODUCT_DETAILS
} from '../config/actionTypes';

export function addToCard(state = {}, action) {
    switch (action.type) {
        case ADD_CART:
            return {
                addCart: action.addCart,
                loading: action.loading
            };
        case ADD_CART_LOADING:
            return {
                addCart: null,
                loading: action.loading
            };
        case ADD_CART_FAIL:
            return {
                addCart: null,
                loading: action.loading
            };
        default:
            return state
    }
}

export function similarProducts(state = {}, action) {
    switch (action.type) {
        case GET_SIMILAR_PRODUCTS:
            return {
                data: action.data
            };
        default:
            return state
    }
}

export function productDetails(state = {}, action) {
    switch (action.type) {
        case GET_PRODUCT_DETAILS:
            let product;
            if(action.data.childId && action.data.childId !== action.data.product.id){
                const product1 = action.data.product.children.filter(item => item.id === action.data.childId)[0]
                console.log(7777777, product1.instructions)
                product = {...action.data.product,
                    brand: product1.brand,
                    cannabinoids_description: product1.cannabinoids_description,
                    categories: product1.categories,
                    description: product1.description,
                    id: product1.id,
                    name: product1.name,
                    pictures: product1.pictures,
                    product_cbd: product1.product_cbd,
                    product_cbd_content: product1.product_cbd_content,
                    product_thc: product1.product_thc,
                    product_thc_content: product1.product_thc_content,
                    quantity: product1.quantity,
                    unit_price: product1.unit_price,
                    variation: product1.variation,
                    sales_price: product1.sales_price,
                    sales_discount_percentage: product1.sales_discount_percentage,
                    parent_id: product1.parent_id,
                    product_type_name: product1.product_type_name,
                    descrition: product1.descrition,
                    meta_alt_tag: product1?.meta_alt_tag,
                    net_weight: product1?.net_weight,
                    net_weight_g: product1?.net_weight_g,
                    showLowQuantity: product1?.showLowQuantity,
                    personalities: product1.personalities && Array.isArray(product1.personalities) ? product1.personalities : [],
                    tags: product1.tags && Array.isArray(product1.tags) ? product1.tags : [],
                    instructions: product1.instructions && Array.isArray(product1.instructions) ? product1.instructions : [],
                    terpenes: product1.terpenes && Array.isArray(product1.terpenes) ? product1.terpenes : [],
                    is_catalogue: product1?.is_catalogue,
                    meta_title: product1?.meta_title,
                    meta_description: product1?.meta_description,
                }
            }
            else product = action.data.product
            return {
                product: product.children ? {...product, children:[action.data.product, ...product.children]}:product ,
            };
        case FAILED_PRODUCT_DETAILS:
            return {
                error: action.error
            };
        case CHANGE_PRODUCT_DETAILS:
            return {
                product: {
                    ...state.product,
                    brand: action.changeProduct.product.brand,
                    cannabinoids_description: action.changeProduct.product.cannabinoids_description,
                    categories: action.changeProduct.product.categories,
                    description: action.changeProduct.product.description,
                    id: action.changeProduct.product.id,
                    name: action.changeProduct.product.name,
                    pictures: action.changeProduct.product.pictures,
                    product_cbd: action.changeProduct.product.product_cbd,
                    product_cbd_content: action.changeProduct.product.product_cbd_content,
                    product_thc: action.changeProduct.product.product_thc,
                    product_thc_content: action.changeProduct.product.product_thc_content,
                    quantity: action.changeProduct.product.quantity,
                    unit_price: action.changeProduct.product.unit_price,
                    variation: action.changeProduct.product.variation,
                    sales_price: action.changeProduct.product.sales_price,
                    sales_discount_percentage: action.changeProduct.product.sales_discount_percentage
                }
            }
        default:
            return state
    }
}
