import React, {useRef} from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import StyledLocationWarningCopy from "./LocationWarningCopy.styled";
import StyledPlacesContainerBundle from "./LocationSearchInputBundle.styled";
import InputZipBundle from "../forms/InputZipBundle";

const LocationSearchInputBundle = React.memo(props => {
    const { address, handleChange, handleSelect, suggestionData, disabled, notDelivery, required, placeholder, enterZip, streetSearch, locationSearch, className, errorMessage, setIsAddress, onBlur, handleWrongAddress } = props;
    const myRef = useRef();
    const onSelectAddress = address => {
        geocodeByAddress(address)
            .then(results => {
                setIsAddress && setIsAddress(true);
                handleSelect(results[0].formatted_address)
            })
            .catch(() => {
                setIsAddress && setIsAddress(false)
                handleWrongAddress && handleWrongAddress()
            } );
    };

    return (
        <PlacesAutocomplete
            searchOptions={{
                types: streetSearch ? ['address'] : [],
                componentRestrictions: { country: "us"} ,
                location: new window.google.maps.LatLng(36.778259,-119.417931),
                radius: 762000
            }}
            value={address}
            onChange={address=> {
                const re =/^[\w-_.#&',/ áéíóúüñ\u2014\u2013]*$/;
                if (address === '' || re.test(address)) {
                    handleChange(address)
                }
                setIsAddress && setIsAddress(false)
            }}
            onSelect={onSelectAddress}
            ref={myRef}
        >
            {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading
              }) => (
                <>
                    <InputZipBundle
                        {...getInputProps({
                            placeholder: `${placeholder ? placeholder : "Enter Your Location" }`,
                            name: "address",
                            className: `location-search-input t-font-DINNextLTPro-Regular ${disabled ? "disabled" : ""} ${className}`,
                            required: required,
                            disabled,
                            onBlur : onBlur
                        })}
                    />
                    <StyledPlacesContainerBundle
                        className={`autocomplete-dropdown-container ${
                            suggestions.length > 0 ? "active" : ""
                        }`}
                    >
                        {loading && <div>Loading...</div>}
                        {(suggestionData? suggestionData : suggestions).map(suggestion => {
                            const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                            // // inline style for demonstration purpose
                            // const style = suggestion.active
                            //     ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            //     : { backgroundColor: '#ffffff', cursor: 'pointer' };

                            return (
                                <div
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion, {
                                        className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </StyledPlacesContainerBundle>
                    {notDelivery && <StyledLocationWarningCopy className="t-body-small">Delivery isn’t available in your location, but you can view the shop.</StyledLocationWarningCopy>}
                    {errorMessage && <StyledLocationWarningCopy className="t-body-small">Delivery isn’t available in this location</StyledLocationWarningCopy>}
                    {locationSearch && <StyledLocationWarningCopy className="t-body-small">Please select a full shipping address.</StyledLocationWarningCopy>}
                </>
            )}
        </PlacesAutocomplete>
    );
});

LocationSearchInputBundle.propTypes = {
    address: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    handleSelect: PropTypes.func.isRequired,
    notDelivery: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string
};
LocationSearchInputBundle.defaultProps = {
    disabled: false
};
export default LocationSearchInputBundle;
