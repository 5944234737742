/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import React from "react";
import StyledMainMenuEverscore from "./MainMenuEverScore.styled";
import MainMenuCardEverscore from "../../MainMenuCard/MainMenuCardEverscore/MainMenuCardEverscore";
import { Link } from "react-router-dom";
import SVGIcon from "../../utility/SVGIcon";
import { personalityPT, featuredBrandPT, categoriesPT, discoversAllPT } from "../../../config/prop-types"

const MainMenuEverscore = React.memo(props => {
    const {
        isOpen,
        setMenuOpen,
        featuredBrand,
        categories,
    } = props;

    const closeMenu = () => {
        setMenuOpen(false)
    }

    const thc = [
        {id: "3", name: "Hits Hard", uri_param: "highestTHC" },
        {id: "2", name: "Regular", uri_param: "mediumTHC" },
        {id: "1", name: "Low Dose" , uri_param: "lowTHC" },
    ]
    return (
        <StyledMainMenuEverscore data-testid="main_menu" className={`responsive_menu ${isOpen ? "" : "is-hidden"}`}>
            <div className="main_menu">
                {categories && categories.length > 0 &&
                    <div>
                        <h3 className="main_menu__heading t-shelf-heading-everscore">Shop By Form</h3>
                        <div className="main_menu__list">
                            {categories.slice(0, 9).map((item,i)=> {
                                return <MainMenuCardEverscore
                                    key={i}
                                    id={item.uri_param}
                                    heading={item.name}
                                    type="category"
                                    setMenuOpen={setMenuOpen}
                                />
                            })}
                        </div>
                    </div>
                }
                {thc &&
                    <div>
                        <div>
                            <h3 className="main_menu__heading t-shelf-heading-everscore">Shop By Potency</h3>
                            <div className="main_menu__list">
                                {thc.map((item, i)=> {
                                    return <MainMenuCardEverscore
                                        key={i}
                                        id={item.uri_param}
                                        heading={item.name}
                                        type="thc"
                                        setMenuOpen={setMenuOpen}
                                    />
                                })}
                            </div>
                        </div>
                    </div>
                }

                {featuredBrand && featuredBrand.length > 0 && featuredBrand.map((item,i)=>{
                    return (item.brands.length > 0 && <div className="main-menu__brands" key={i}>
                        <h3 className="main_menu__heading__brands t-shelf-heading-everscore">Featured Brands</h3>
                        <div className="main_menu__list-grid">
                            {item.brands && item.brands.map((brandItem,t)=> {
                                return <MainMenuCardEverscore
                                    key={t}
                                    id={brandItem.uri_param}
                                    heading={brandItem.name}
                                    type="brand"
                                    setMenuOpen={setMenuOpen}
                                />
                            })}
                        </div>
                    </div>)
                })}

                <Link to="/shop" className="main-menu-link main-menu-link__shop" onClick={closeMenu}>
                    SHOP ALL
                    <SVGIcon
                        className="up-arrow-icon"
                        name="arrow-right"
                    />
                </Link>
            </div>
        </StyledMainMenuEverscore>
    );
});

MainMenuEverscore.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setMenuOpen: PropTypes.func.isRequired,
    featuredBrand: featuredBrandPT,
    discoversAll: discoversAllPT,
    categories: categoriesPT,
    personality: personalityPT,
};

export default MainMenuEverscore;
