import styled from "styled-components";

const ProfileDropdown = styled.nav`
  position: relative;
  flex-shrink: 0;

  .profile-dropdown__media {
    padding: 8px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    outline: none;
    transition: var(--button-transition);
    color: ${({theme}) => theme.colors.brand};

    &:hover,
    &:focus {
      fill: ${({theme}) => theme.colors.brand};
    }

    svg {
      width: 24px;
      height: 24px;
      fill: inherit;
    }
  }

  .profile-dropdown__options {
    min-width: 130px;
    position: absolute;
    right: 0;
    top: 38px;
    background-color: ${({theme}) => theme.colors.white};
    border: 1px solid ${({theme}) => theme.colors.gray3};
    border-radius: 4px;
    z-index: 1;

    &[data-profile-dropdown-state=false] {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
      clip: rect(1px, 1px, 1px, 1px);
      white-space: nowrap; /* added line */
    }
  }

  .profile-dropdown__option {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid ${({theme}) => theme.colors.gray3};
    }
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }

  .profile-dropdown__option__link,
  .profile-dropdown__option__sign-out {
    width: 100%;
    color: ${({theme}) => theme.colors.gray4};

    &:hover,
    &:focus {
      color: ${({theme}) => theme.colors.brand};
    }
  }

  .profile-dropdown__option__link {
    padding: 1rem .75rem;
    text-decoration: none;
    transition: var(--button-transition);
  }

  .profile-dropdown__option__sign-out {
    padding: .75rem;
    background-color: ${({theme}) => theme.colors.gray1};
    border-radius: 0 0 4px 4px;
    transition: var(--button-transition);
  }
`;

export default ProfileDropdown;