/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import StyledModalVerificationEverscore from "./ModalVerificationEverscore.styled";
import LocationSearchInputEverscore from "../LocationSearchInput/LocationSearchInputEverscore";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { locationActions } from '../../actions/index';
import { connect } from "react-redux";
import LoadingEllipsis from "../LoadingEllipsis/LoadingEllipsis";
import SVGIcon from "../utility/SVGIcon";
import ButtonOutLineEverscore from "../ui/ButtonOutlineEverscore";
import InputAuthorization from "../forms/InputAuthorization/InputAuthorization";
import {isKiosk} from "../../config/constants";
import { addressPT } from "../../config/prop-types";

let zipCode = null;

const ModalVerificationEverscore = props => {
    const { dispatch, address, isShown } = props;
    const [deliveryLocation, setDeliveryLocation] = useState({
        address: "",
        lat: "",
        lng: "",
        zip: ""
    });
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState(false);
    const [isDelivery, setIsDelivery] = useState(false);
    const [terminalId, setTerminalId] = useState('');
    const [isAddress, setIsAddress] = useState(false);

    useEffect(() => {
        if(isShown){
            document.body.classList.add("no-scroll");
            const appContainer = document.querySelector(".App")
            if(appContainer) {
                appContainer.classList.add("has-blur");
            }
        }
    }, []);

    useEffect(() => {
        if(deliveryStatus === "notDelivery") {
            setIsDelivery(true)
        } else {
            setIsDelivery(false)
        }
    }, [deliveryStatus]);

    useEffect(() => {
        dispatch(locationActions.selectedLocation());
    }, []);

    useEffect(() => {
        if(address){
            const data = {
                ...deliveryLocation,
                address: address.address,
                lat: address.lat,
                lng: address.lng,
                zip: address.zip,
            };
            setDeliveryLocation(data);
            handleOnSelect(address.address)
        }
    }, [ address ]);

    const handleOnChange = address => {
        setIsDelivery(false)
        setErrorMessage(false)
        const data = {
            ...deliveryLocation,
            address: address
        };
        setDeliveryLocation(data);
    };

    const getResultData = (Data, name) => {
        const Result = Data.address_components.filter(
            item => item.types[0] === name
        );
        if (Result.length > 0) {
            return Result[0].long_name;
        }
        return null;
    };

    const getZipCode = async result => {
        zipCode = getResultData(result, "postal_code");
        return result;
    };

    const handleOnSelect = (address) => {
        if(address && (address.trim() !== '')) {
            const data = { ...deliveryLocation };
            setDeliveryStatus("");
            setLoadingStatus(true);
            geocodeByAddress(address)
                .then((results) => getZipCode(results[0]))
                .then((results) => getLatLng(results))
                .then((latLng) => {
                    data.address = address;
                    data.lat = latLng.lat;
                    data.lng = latLng.lng;
                    data.zip = zipCode;
                    setDeliveryLocation(data);
                    locationActions.locationSelect(data).then((data) => {
                        setLoadingStatus(false);
                        if (data.status) {
                            setDeliveryStatus(data.status);
                            localStorage.setItem(
                                "deliveryStatus",
                                JSON.stringify(data.status)
                            );
                        }
                    });
                }).catch(()=>{
                setLoadingStatus(false);
                setErrorMessage(true)
            });
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        props.onChange(true)
        const appClass = document.getElementsByClassName("App")[0];
        if(appClass) {
            appClass.classList.remove("has-blur")
        }

        document.body.classList.remove("no-scroll")
        if(terminalId){localStorage.setItem('terminal_id', JSON.stringify(terminalId))}
        return dispatch(locationActions.selectedPostLocation(deliveryLocation))
    };

    const clearSearch = () =>{
        const data = { ...deliveryLocation };
        data.address = '';
        setDeliveryLocation(data);
    };

    const noLinkAction = (e) => {
        e.preventDefault();
    }

    const handleClickOutside = () => {
        if (!isAddress) {
            const data = { ...deliveryLocation };
            data.address = '';
            setDeliveryLocation(data);
        }
    };
    const handleWrongAddress = () => {
        if (!isAddress) {
            clearSearch()
        }
    };

    return (
        <>
            <StyledModalVerificationEverscore isShown={isShown}>
                <div className="verification" role="dialog" data-testid="verification-modal">
                    <SVGIcon name="everscore-logo__vertical" className="verification__logo" />
                    <div className="verification__form">
                        <label className="verification__form__label">
                        <div className="verification__form__label-text first t-font-NHaasGroteskDSPro-65Md">Get ready to explore the most</div>
                        <div className="verification__form__label-text t-font-NHaasGroteskDSPro-65Md">innovative cannabis brands.</div>
                        <div className="verification__form__label-text-second t-font-NHaasGroteskDSPro-55Rg">Enter your address and discover cannabis products available in your area</div>
                            <div className="verification__form__address-input">
                                <LocationSearchInputEverscore
                                    className="verification__input t-font-NHaasGroteskDSPro-45Lt"
                                    address={deliveryLocation.address || ''}
                                    handleChange={handleOnChange}
                                    handleSelect={handleOnSelect}
                                    notDelivery={isDelivery && deliveryStatus === "notDelivery"}
                                    enterZip={true}
                                    errorMessage={errorMessage}
                                    setIsAddress={setIsAddress}
                                    onBlur={handleClickOutside}
                                    handleWrongAddress={handleWrongAddress}
                                />
                                {deliveryLocation.address &&
                                <button  className="verification-delivery_address__clear" onClick={clearSearch}>
                                    <SVGIcon name="close" width="16" />
                                    <span className="t-hidden">clear search</span>
                                </button>
                                }
                            </div>
                        </label>
                        {isKiosk &&
                        <div className="verification__form__ip-address-input">
                            <div className="t-sub-heading verification__form__label-text">Please add POS generated ID</div>
                            <InputAuthorization
                                inputName="terminalId"
                                value={terminalId}
                                placeholder="ex. ASD12345"
                                type="text"
                                className="terminal-id"
                                onChange={(e) => setTerminalId(e.target.value.trim())}
                                required={false}
                            />
                        </div>
                        }
                    </div>

                    <div className={`verification__age ${!deliveryStatus || !deliveryLocation.address.trim() ? "is-disabled" : ""}`}>
                        <p className="t-copy-medium verification__form__age-copy t-font-NHaasGroteskDSPro-65Md">Are you 21 or Over? or 18 with a medical card?</p>
                        <div className="buttons-container">
                            <ButtonOutLineEverscore className="verification__yes__button t-font-NHaasGroteskDSPro-55Rg" onClick={handleSubmit} disabled={!deliveryStatus || !deliveryLocation.address.trim()}>
                                {loadingStatus ?
                                    <>
                                        &nbsp;
                                        <LoadingEllipsis color="var(--color-everscoreBrandConst)" size=".3rem" />
                                    </>
                                    :
                                    <>
                                    YES
                                    <span className="t-hidden">Yes button</span>
                                    </>
                                }
                            </ButtonOutLineEverscore>
                            <a href="https://google.com" onClick={ (e) => (!deliveryStatus || !deliveryLocation.address.trim()) ? noLinkAction(e) : ""} className={`verification__button t-font-NHaasGroteskDSPro-55Rg ${!deliveryStatus || !deliveryLocation.address.trim() ? "is-disabled" : ""}`}>
                                {loadingStatus ?
                                    <>
                                        &nbsp;
                                        <LoadingEllipsis color="var(--color-everscoreBrandConst)" size=".3rem" />
                                    </>
                                    :
                                    "NO"
                                }
                            </a>
                        </div>
                    </div>

                </div>
            </StyledModalVerificationEverscore>
        </>
    )
};
const mapStateToProps = ({ userAddress }) => ({
    address: userAddress.address
});

ModalVerificationEverscore.propTypes = {
    className: PropTypes.string,
    isShown: PropTypes.bool,
    address: addressPT,
};

export default connect(mapStateToProps)(ModalVerificationEverscore);
