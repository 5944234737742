import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { debounce } from "lodash";
import StyledInputSearchAhead from "./InputSearchAhead.styled";
import SVGIcon from "../utility/SVGIcon";
import { autocompleteFunc,  } from '../../actions/index';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {history} from '../../helpers';
import ButtonSecondary from "../ui/ButtonSecondary";
import {selectedTheme} from "../../config/constants";
import { productsPT } from '../../config/prop-types';

const isFlowerTheme = selectedTheme === "FlowerTheme";

const InputSearchAhead = React.memo(props => {
    const { className, inputName, placeholder, dispatch, /* catList */ autocompleteList } = props;
    const [searchInput, setSearchInput] = useState('');
    const [openSearch, setOpenSearch] = useState(false);
    const [localAutocompleteList, setLocalAutocompleteList] = useState('');
    const myRef = useRef();
    const focusInput = useRef(null);

    /* Add and remove focus on search input when modal is closed and opened */
    useEffect(() => {
        openSearch ? focusInput.current.focus() : focusInput.current.blur();
    })

    useEffect(() => {
        if(autocompleteList && searchInput){
            const list = autocompleteList.map( item => {return { name: item.name, id: item.uri_param, brand: item.brand}});
            setLocalAutocompleteList({type: 'autocomplete', list});
        }
    }, [autocompleteList, searchInput]);


    /* Header button function to open modal */
    const openMobileSearch = () => {
        document.body.classList.add("no-scroll");
        setOpenSearch(!openSearch)
    };

    /* Modal button function to close modal */
    const closeMobileSearch = () => {
        document.body.classList.remove("no-scroll");
        setOpenSearch(!openSearch)
    };

    /* Clear Autocomplete list */
    const clearAutocomplete = () => {
        setLocalAutocompleteList('');
    };

    /* Search result query functions */
    const sendQuery = (e) => {
        e.preventDefault();
        clearAutocomplete();
        document.body.classList.remove("no-scroll");
        history.push(`/shop?query=${searchInput}`);
        setOpenSearch(false)
        
    };

    const sendBlankQuery = (e) => {
        e.preventDefault();
        clearAutocomplete();
        document.body.classList.remove("no-scroll");
        history.push(`/shop`);
        setOpenSearch(false)
    };

    /* Form input functions */
    const selectSearchResultLink = ( name ) => {
        clearAutocomplete();
        document.body.classList.remove("no-scroll");
        setSearchInput( name );
        setOpenSearch(false)
    };

    const clearInput = ( name ) => {
        clearAutocomplete();
        setSearchInput('');
        focusInput.current.value = '';
        history.push('/shop')
    };

    const onValueChange = inputValue => {
        if(inputValue.length > 1){
            dispatch(autocompleteFunc.autocomplete(inputValue));
        }
    };

    const debounceFn = useMemo(() => debounce(onValueChange, 1000), []);
    const handleChange = (e) => {
        setSearchInput(e.target.value)
        debounceFn(e.target.value);
    };

    /* Saving this function just in case team feedback leads to adding this back
    const onFocus = () => {
        if(!searchInput && catList && catList.length){
            const list = catList.map( item => {return { name: item.name, id: item.id}});
            setLocalAutocompleteList({type: 'categories', list});
            document.body.classList.add("open_autocomplete");
        }
    }; */

    return (
        <StyledInputSearchAhead ref={myRef} className={className}>
            <>
            {/* 
                ------
                Icon trigger to open full screen modal 
                ------
            */}
            <button type="submit" className="t-form-button input-search__icon" onClick={openMobileSearch}>
                {isFlowerTheme ? <SVGIcon name="flower-search-icon" /> :<SVGIcon name="search"/>}
                <span className="t-hidden">Explore the menu</span>
            </button>

            {/*
                ------
                Full screen modal that contains all search ahead functionality
                ------
            */}
            <div className={`input-search-ahead ${openSearch ? "is-active" : ""}`} role="dialog" data-testid="fullscreen-modal">
                <div className="input-search-ahead__wrap">
                    <button className="input-search-ahead__close-modal" onClick={closeMobileSearch}>
                        <span className="t-hidden">Close global search modal</span>
                        <SVGIcon name="close" />
                    </button>
                </div>

                <form className="input-search-ahead__form" onSubmit={sendQuery}>
                    <button className="t-form-button input-search-ahead__search-button">
                        <span className="t-hidden">Search for products</span>
                        <SVGIcon name="search" />
                    </button>
                    <input
                        className="t-body-medium input-search-ahead__form-input"
                        name={inputName}
                        type="text"
                        placeholder={placeholder}
                        onChange={handleChange}
                        /* onFocus={onFocus} */
                        autoComplete="off"
                        // value={searchInput}
                        ref={focusInput}
                    />
                    {searchInput &&
                        <button type="button" className="input-search-ahead__clear-search" onClick={clearInput}>
                            <SVGIcon name="closeCircle" width="16" />
                            <span className="t-hidden">Clear search</span>
                        </button> 
                    }
                </form>

                    
                {searchInput && localAutocompleteList && localAutocompleteList.list && localAutocompleteList.list.length ?
                <div className="input-search-ahead__results" >
                    <div className="input-search-ahead__results__header">
                        <h2 className="t-section-heading input-search-ahead__results__heading">SEARCH RESULTS</h2>
                        <button className="t-body-small input-search-ahead__view-all" onClick={sendQuery}>VIEW ALL</button>
                    </div>

                    <ul className="input-search-ahead__results__list">
                    {searchInput && localAutocompleteList && localAutocompleteList.list.map((item, i) =>
                        (i < 15) ?
                        <li key={item.id} className="input-search-ahead__results__list-item">
                                <Link
                                    className="t-body input-search-ahead__results__item"
                                    onClick={e => selectSearchResultLink( item.name )}
                                    to={localAutocompleteList.type==='autocomplete' ?
                                    `/${item.brand}/${item.id}` :
                                    `/shop?category=${item.uri_param}`}>
                                    {item.name}
                                </Link>
                            </li>
                        :
                        ''
                        )}
                    </ul>
                </div>
                :
                ''}                        
                {localAutocompleteList && localAutocompleteList.list && localAutocompleteList.list.length === 0 ?
                    <div className="input-search-ahead__no-result">
                        <h3 className="t-section-heading input-search-ahead__no-result__heading">Oops… we couldn’t find any results for that</h3>
                        <ButtonSecondary className="input-search-ahead__search-all" onClick={sendBlankQuery}>SEARCH ALL PRODUCTS</ButtonSecondary>
                    </div>
                :''}
            </div>
            </>
        </StyledInputSearchAhead>
    );
});

const mapStateToProps = ({ autocomplete, categories }) => ({
    autocompleteList: autocomplete.list,
    catList: categories.catList,
});

InputSearchAhead.propTypes = {
    inputName: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    autocompleteList: productsPT,
};

export default connect(mapStateToProps)(InputSearchAhead);
