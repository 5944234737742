import styled from "styled-components";

const StyledModalVerification = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform : ${props => props.isShown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity : ${props => props.isShown ? '1' : '0'};
  z-index: 10;
  filter: blur(0) !important;

  .verification {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 727px;
    min-height: 497px;
    background-image: url("/images/Verification_Image.jpg");
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 12;
    
    @media (max-width: 799px) {
      padding: 20px 0 40px;
      max-width: 360px;
      min-height: unset;
      background-repeat: round;
    }
    @media (min-width: 800px) {
      padding: 36px 0;

    }
  }
  
  .verification__logo {
    align-self: center;
    
    @media (max-width: 799px) {
      width: 140px;
      height: 64px;
    }
    @media (min-width: 800px) {
      margin: 2.5rem 0;
      width: 200px;
      height: 80px;
    }
  }

  .verification__heading {
    color: ${({theme}) => theme.colors.brandDark};

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 1rem;
    }
  }
  
  .verification__age {
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    
    &.is-disabled {
      color: ${({theme}) => theme.colors.gray3};
    }
  }
  
  .verification__button,
  .verification__age__button {
    @media (min-width: 800px) {
      width: 132px;
    }
  }

  .verification__button {
    padding: 6px 31px;
    line-height: normal;
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.colors.brandDark};
    background-color: transparent;
    color: ${({ theme }) => theme.colors.brandDark};
    font-size: var(--type-scale-18);
    font-weight: var(--weight-bold);
    font-family: var(--font-Moderat-Medium);
    text-align: center;
    flex-shrink: 0;
    transition: var(--button-transition);
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.brandDark};
      border-color: ${({ theme }) => theme.colors.brand};
      color: ${({ theme }) => theme.colors.white};
    }
    
    &.is-disabled {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.gray3};
      color: ${({ theme }) => theme.colors.gray3};
      cursor: default;
    }

    @media (max-width: 799px) {
      margin-left: 20px;
      padding: 8px 44px;
      font-size: var(--type-scale-14);
    }
    @media (min-width: 800px) {
      margin-left: 16px;
    }
  }

  .verification__form {
    display: flex;
    flex-direction: column;
    max-width: 512px;
    width: 100%;

    @media (max-width: 799px) {
      margin-bottom: 46px;
      margin-top: 34px;
      max-width: 290px;
    }
    @media (min-width: 800px) {
      margin-bottom: 90px;
    }
  }

  .verification__form__label {
    width: 100%;  
    position: relative;
    color: ${({theme}) => theme.colors.brandDark};
  }
  
  .verification__form__age-heading {
    margin-bottom: .75rem;
    color: inherit;

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: .75rem;
    }
  }
  
  .verification__form__label-text {
    margin-bottom: .75rem;
    font-family: var(--font-Moderat-Bold);
    font-weight: var(--weight-bold);
    @media (max-width: 799px) {
      margin-bottom: .25rem;
    }
  }

  .verification__form__age-copy {
    color: inherit;
    font-family: var(--font-Moderat-Bold);
    font-weight: var(--weight-bold);

    @media (max-width: 799px) {
      margin-bottom: 24px;
    }
    @media (min-width: 800px) {
      margin-bottom: 26px;
    }
  }

  .verification__footnote {
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.brandDark};

    .t-link {
      color: inherit;
    }
    .t-link:hover,
    .t-link:focus {
      color: ${({theme}) => theme.colors.brand};
    }
  }
  .not_deliv{
    margin: 3px 0;
    font-size: 12px;
  }

  /* Temporary until v2 design */
  .verification__input {
    background-color: transparent;
    border-radius: 0;
    transition: var(--button-transition);
    padding-right: 40px;

    &:hover,
    &:focus {
      border-color: ${({theme}) => theme.colors.brand};
    }
  }

  .verification__form__address-input {
    position: relative;
  }
  .verification__form__ip-address-input {
    margin-top: 15px;
    .placeHold{
      display: none;
    }
    .verification__form__label-text{
      color: ${({theme}) => theme.colors.brandDark};
    }
    input{
      border-radius: 0;
    }
  }
  .verification-delivery_address__clear {
    border: none;
    outline: none;
    right: 0;
    top: 0.625rem;
    width: 16px;
    height: 16px;
    position: absolute;
    align-self: center;

    svg {
      fill: ${({theme}) => theme.colors.gray3};
      width: inherit;
      height: inherit;

    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
    @media (max-width: 799px) {
      top: 0.325rem;
    }
  }

  .buttons-container {
    @media (max-width: 799px) {
      display: flex;
      justify-content: center;
    }
    @media (min-width: 800px) {
      display: block;
    }
  }
`;

export default StyledModalVerification;
