import React, {Suspense, useMemo} from "react";
import SpinnerLoader from "../components/Spinner/SpinnerLoader";
import styled from "styled-components";
import Spinner from "../components/Spinner/Spinner";

const StyledMain = styled.main`
  display: flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
`;

const LoaderComponent = React.memo(() => (
  <StyledMain>
    <h2 className="loading-container text-center">
      <Spinner className="spinner" color="var(--color-brand)" size={100}/>
      <span className="t-hidden">loading</span>
    </h2>
  </StyledMain>
));
const LazyLoad = React.memo(({ component: Component, loader: Loader, ...rest }) => (

  <>
    <Suspense fallback={<LoaderComponent />} >
      <Component {...rest} />
    </Suspense>
  </>
));

export default LazyLoad;
