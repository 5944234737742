import { GET_YOTPO_IDS, GET_YOTPO_IDS_REQUEST } from "../config/actionTypes";

const initialState = {
    yotpoIds: null,
    loading: false,
}

export function yotpoData(state=initialState, action) {
    switch (action.type) {
        case GET_YOTPO_IDS_REQUEST:
            return {
                ...state,
                yotpoIds: null,
                loading: true
            };
        case GET_YOTPO_IDS:
            return {
                ...state,
                yotpoIds: [ ...action.data],
                loading: false
            };
        default:
            return state
    }
}