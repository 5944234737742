import { userService } from '../services';
import { GET_USER_ADDRESS, GET_LOCATION_INFO, GET_LOCATION_INFO_LOADING, GET_LOCATION_PAGES, FAILED_LOCATION_PAGES } from '../config/actionTypes';
import { storeDomain } from "../config/constants";
import qs from "qs";

export const locationActions = {
    locationSelect,
    selectedLocation,
    selectedPostLocation,
    locationSelectInHeader,
    locationInfo,
    locationPages,
};

function locationSelect(data) {
     return  userService.PostInfo('/locations/delivery_in_range',data)
}

function selectedLocation() {
    return dispatch => {
        userService.GetInfo('/locations/selected','')
            .then(
                data => {
                    if( data && data.GUID){
                        localStorage.setItem(`guId_${storeDomain}`, JSON.stringify(data.GUID));
                    }
                    dispatch(success(data));
                }
            );
    };
    function success(address) { return { type: GET_USER_ADDRESS, address } }
}
function selectedPostLocation(data) {
    return dispatch => {
        return userService.PostInfo('/locations/selected', data)
            .then(
                data => {
                    localStorage.setItem('initial', true);
                    if(data.GUID){
                        localStorage.setItem(`guId_${storeDomain}`, JSON.stringify(data.GUID));
                    }
                    window.location.reload()
                }
            );
    };
}
function locationSelectInHeader(data) {
    return  userService.PostInfo('/locations/selected',data)
}

function locationInfo() {
    return dispatch => {
        dispatch(loading(true));
        userService.GetInfo('/locations/info','')
            .then(
                data => {
                    dispatch(success(data));
                }
            ).catch(
                ()=> {
                  dispatch(loading(false));
                }
        );
    };
    function success(locationPhone) { return { type: GET_LOCATION_INFO, locationPhone, loading: false } }
    function loading(loading) { return { type: GET_LOCATION_INFO_LOADING, locationPhone: {}, loading } }
}

function locationPages(location) {
    return dispatch => {
        dispatch(requestLoader());
        userService.GetInfo('/locations/pages/', location)
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(successLoader());
                },
                (err) =>{
                  dispatch(failureLoader());
                  dispatch(failed(err));
                }
        );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function successLoader() { return { type: 'SUCCESS' } }
    function failureLoader() { return { type: 'FAILURE' } }
    function failed(error) { return { type: FAILED_LOCATION_PAGES, error }}
    function success(locationPages) { return { type: GET_LOCATION_PAGES, locationPages, loading: false } }
};

