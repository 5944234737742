export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_STORE_INFO = 'GET_STORE_INFO';
export const GET_USER_ADDRESS = 'GET_USER_ADDRESS';
export const GET_LOCATION_INFO = 'GET_LOCATION_INFO';
export const GET_LOCATION_INFO_LOADING = 'GET_LOCATION_INFO_LOADING';
export const GET_LOCATION_PAGES = 'GET_LOCATION_PAGES';
export const FAILED_LOCATION_PAGES = 'FAILED_LOCATION_PAGES';
export const GET_CART_COUNT = 'GET_CART_COUNT';
export const USER_AUTOCOMPLETE = 'USER_AUTOCOMPLETE';
export const GET_CART_ITEMS = 'GET_CART_ITEMS';
export const CART_LOADING = 'CART_LOADING';
export const GET_GIV_AWAY = 'GET_GIV_AWAY';
export const GET_ADD_ONS = 'GET_ADD_ONS';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';

export const GET_BRANDS = 'GET_BRANDS';
export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SHELF = 'GET_BRANDS_SHELF';
export const GET_DYNAMIC_SHELF = 'GET_DYNAMIC_SHELF';
export const SEARCH_BRANDS = 'SEARCH_BRANDS';
export const BRAND_LOADING = 'BRAND_LOADING';

export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const GET_PRODUCT_TYPES_REQUEST = 'GET_PRODUCT_TYPES_REQUEST';

export const GET_DISCOVER = 'GET_DISCOVER_ALL';
export const GET_DISCOVER_REQUEST = 'GET_DISCOVER_REQUEST';

export const GET_PERSONALITY = 'GET_PERSONALITY';
export const GET_PERSONALITY_REQUEST = 'GET_PERSONALITY_REQUEST';

export const GET_BRANDS_BY_TYPES = 'GET_BRANDS_BY_TYPES';
export const GET_BRANDS_BY_TYPES_REQUEST = 'GET_BRANDS_BY_TYPES_REQUEST';

export const GET_SEARCH_RESULTS_CATALOG = 'GET_SEARCH_RESULTS_CATALOG';
export const GET_SEARCH_RESULTS_CATALOG_LOADING = 'GET_SEARCH_RESULTS_CATALOG_LOADING';

export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const POST_USER_AVATAR = 'POST_USER_AVATAR';

export const FAILED_PRODUCT_DETAILS = 'FAILED_PRODUCT_DETAILS';

// change product
export const CHANGE_PRODUCT_SEARCH = 'CHANGE_PRODUCT_SEARCH';
export const CHANGE_PRODUCT_DETAILS = 'CHANGE_PRODUCT_DETAILS';

// user orders
export const GET_SEARCH_RESULTS = 'GET_SEARCH_RESULTS';
export const LOAD_MORE_SEARCH_RESULTS_CATALOG = 'LOAD_MORE_SEARCH_RESULTS_CATALOG';

export const GET_CAROUSEL = 'GET_CAROUSEL';
export const GET_USER_ORDERS = 'GET_USER_ORDERS';
export const GET_USER_ORDERS_SUCCESS = 'GET_USER_ORDERS_SUCCESS';

export const GET_USER_ORDER_BY_ID = 'GET_USER_ORDER_BY_ID';
export const GET_USER_ORDER_BY_ID_SUCCESS = 'GET_USER_ORDER_BY_ID_SUCCESS';
export const POST_ORDER_DRIVER_RATE = 'POST_ORDER_DRIVER_RATE';
export const POST_ORDER_DRIVER_RATE_SUCCESS = 'POST_ORDER_DRIVER_RATE_SUCCESS';
export const POST_ORDER_DRIVER_RATE_FAIL = 'POST_ORDER_DRIVER_RATE_FAIL';
export const CLEAR_RATE = 'CLEAR_RATE';
export const PATCH_USERS_WALLET_ADDRESS = 'PATCH_USERS_WALLET_ADDRESS';

export const GET_BEST_SELLERS = 'GET_BEST_SELLERS';
export const GET_PRODUCTS_SALES = 'GET_PRODUCTS_SALES';
export const GET_STAFF_PICKS = 'GET_STAFF_PICKS';
export const GET_HERO_BANNER = 'GET_HERO_BANNER';
export const GET_BRAND_SPOTLIGHT = 'GET_BRAND_SPOTLIGHT';

export const POST_PROMO = 'POST_PROMO';
export const POST_PROMO_LOADING = 'POST_PROMO_LOADING';
export const POST_PROMO_FAIL = 'POST_PROMO_FAIL';
export const POST_ORDER = 'POST_ORDER';
export const POST_MEDICAL_ID = 'POST_MEDICAL_ID';
export const POST_MEDICAL_ID_REQUEST = 'POST_MEDICAL_ID_REQUEST';
export const POST_MEDICAL_ID_FAIL = 'POST_MEDICAL_ID_FAIL';

export const POST_CARD_ID = 'POST_CARD_ID';
export const POST_CARD_ID_REQUEST = 'POST_CARD_ID_REQUEST';
export const POST_CARD_ID_FAIL = 'POST_CARD_ID_FAIL';

export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAILED = 'ORDER_FAILED';
export const ORDER_REQUEST = 'ORDER_REQUEST';

export const ORDER_PAYTENDER_SUCCESS = 'ORDER_PAYTENDER_SUCCESS';
export const ORDER_PAYTENDER_FAILED = 'ORDER_PAYTENDER_FAILED';
export const ORDER_PAYTENDER_REQUEST = 'ORDER_PAYTENDER_REQUEST';


export const GET_MEDICAL_ID = 'GET_MEDICAL_ID';
export const GET_MEDICAL_ID_REQUEST = 'GET_MEDICAL_ID_REQUEST';
export const GET_MEDICAL_ID_FAIL = 'GET_MEDICAL_ID_FAIL';

export const GET_CARD_ID = 'GET_CARD_ID';
export const GET_CARD_ID_REQUEST = 'GET_CARD_ID_REQUEST';
export const GET_CARD_ID_FAIL = 'GET_CARD_ID_FAIL';

export const GET_PROMO = 'GET_PROMO';
export const GET_WELCOME_PROMO = 'GET_WELCOME_PROMO';

export const CHECK_SUMMARY = 'CHECK_SUMMARY';
export const CHECK_SUMMARY_REQUEST = 'CHECK_SUMMARY_REQUEST';
export const CHECK_SUMMARY_FAIL = 'CHECK_SUMMARY_FAIL';

export const ADD_CART_LOADING = 'ADD_CART_LOADING';
export const ADD_CART = 'ADD_CART';
export const ADD_CART_FAIL = 'ADD_CART_FAIL';

export const POST_SEND_EMAIL = 'POST_SEND_EMAIL';

export const GET_SIMILAR_PRODUCTS = 'GET_SIMILAR_PRODUCTS';

export const GET_CATEGORIES_ALL = 'GET_CATEGORIES_ALL'
export const GET_BRANDS_ALL = 'GET_BRANDS_ALL'
export const GET_DISCOVERS_ALL = 'GET_DISCOVERS_ALL'
export const GET_PRODUCT_TYPES_ALL = 'GET_PRODUCT_TYPES_ALL'
export const GET_PERSONALITY_ALL = 'GET_PERSONALITY_ALL'
export const GET_BRANDS_BY_TYPES_ALL = 'GET_BRANDS_BY_TYPES_ALL'

export const GET_BRANDS_SEARCH = 'GET_BRANDS_SEARCH'
export const GET_BRAND_PRODUCTS = 'GET_BRAND_PRODUCTS'
export const LOAD_MORE_BRANDS = 'LOAD_MORE_BRANDS'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'

export const GET_PROMO_BAR = 'GET_PROMO_BAR'
export const GET_BLOG_POSTS = 'GET_BLOG_POSTS';
export const GET_BLOG_POSTS_LOADING = 'GET_BLOG_POSTS_LOADING';
export const GET_BLOG_POSTS_CATEGORIES = 'GET_BLOG_POSTS_CATEGORIES';
export const GET_BLOG_POSTS_CATEGORIES_LOADING = 'GET_BLOG_POSTS_CATEGORIES_LOADING';
export const GET_BLOG_POST_DETAILS_LOADING = 'GET_BLOG_POST_DETAILS_LOADING';
export const GET_BLOG_POST_DETAILS = 'GET_BLOG_POST_DETAILS';

export const GET_DISCOVERS_LIST = 'GET_DISCOVERS_LIST';
export const GET_DISCOVERS_LIST_REQUEST = 'GET_DISCOVERS_LIST_REQUEST';

export const GET_FAST_LINK = 'GET_FAST_LINK';
export const GET_FAST_LINK_LOADING = 'GET_FAST_LINK_LOADING';
export const AEROPAY_USER_BANK_INFO = 'AEROPAY_USER_BANK_INFO';
export const AEROPAY_USER_BANK_INFO_LOADING = 'AEROPAY_USER_BANK_INFO_LOADING';
export const EMPTY_FAST_LINK = 'EMPTY_FAST_LINK';
export const EMPTY_AEROPAY_USER_BANK_INFO = 'EMPTY_AEROPAY_USER_BANK_INFO';

export const GET_YOTPO_IDS = 'GET_YOTPO_IDS';
export const GET_YOTPO_IDS_REQUEST = 'GET_YOTPO_IDS_REQUEST';
export const GET_YOTPO_IDS_FAIL = 'GET_YOTPO_IDS_FAIL';

export const GET_LANDING_DATA = 'GET_LANDING_DATA';
export const GET_LANDING_DATA_LOADING = 'GET_LANDING_DATA_LOADING';
export const GET_LANDING_DATA_FAILED = 'GET_LANDING_DATA_FAILED';
export const SET_LANDING = 'SET_LANDING';

export const GET_USER_NFT = 'GET_USER_NFT';