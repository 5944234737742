import styled from "styled-components";

const StyledHeaderMain = styled.header`
    --icon-size: 24px;

    width: 100%;
    background-color: ${({theme}) => theme.colors.background};
    z-index: 3;
    
    .header-main {
        width: 100%;
        position: relative;
        border-bottom: ${({theme}) => `1px solid ${theme.colors.gray2}`};
    }
    
    .header-main__wrap {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        display: flex;
        align-items: center;
        
        @media (max-width: 859px) {
            padding: 10px 8px 10px 0;
        }
        @media (min-width: 860px) {
            padding: 0 32px;
            justify-content: space-between;
        }
    }

    .header-main__logo {
        display: inline-flex;
        
        @media (max-width: 859px) {
            width: 154px;
            height: 19px;
        }
        @media (min-width: 860px) {
            width: 192px;
            height: 24px;
        }
    }

    .header-main__logo-other {
        display: inline-flex;
        height: auto;

        @media (max-width: 859px) {
            margin-right: 16px;
            max-height: 28px;
        }
        @media (min-width: 860px) {
            max-height: 36px;
        }
    }

    .header-main__home {
        display: inline-flex;
    }
    
    .header-main__link {
        padding: 1.5rem 12px;
        color: ${({theme}) => theme.colors.brandDark};
        outline: none;

        @media (max-width: 459px) {
            position: absolute !important;
            height: 1px; 
            width: 1px;
            overflow: hidden;
            clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
            clip: rect(1px, 1px, 1px, 1px);
            white-space: nowrap; /* added line */
        }
        @media (min-width: 860px) {
            margin-left: auto;
        }

    }

    .menu_btn {
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        user-select: none;
        text-decoration: none;

        @media (max-width: 859px) {
            display: none;
        }

        &:hover,
        &:focus {
            color: ${({theme}) => theme.colors.brand};
            cursor: pointer;

            .menu_btn__icon {
                fill: ${({theme}) => theme.colors.brand};
            }
        }
    }

    .menu_btn__icon {
        margin: 2px 4px;
        width: 12px;
        height: 12px;
        fill: ${({theme}) => theme.colors.brandDark};
        flex-shrink: 0;
        transition: transform 0.5s;
        &.isOpen {
            transform: rotate(-180deg);
        }
    }

    .header-main__icons {
        flex-shrink: 0;
        display: flex;

        @media (max-width: 859px) {
            margin-left: auto;
            margin-right: 10px;
        }
        @media (min-width: 860px) {
        }
    }

    .header-main__search-ahead {
        @media (max-width: 859px) {
            margin-right: 8px;
            display: inline-flex;
        }
        @media (min-width: 860px) {
            max-width: 423px;
        }
    }
    
    .cart-icon {
        position: relative;
        text-decoration: none;
        color: ${({theme}) => theme.colors.brand};
        .cart-count {
            position: absolute;
            right: -10px;
            top: 0;
            padding: 4px;
            min-width: 20px;
            height: 20px;
            color: ${({theme}) => theme.colors.brandLight};
            background-color: ${({theme}) => theme.colors.brand};
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            border-radius: 10px;
            font-size: 12px;
        }
    }

    .header-main__icon-button {
        padding: 8px;
        display: inline-flex;
        fill: ${({theme}) => theme.colors.brandDark};
        transition: var(--button-transition);

        &:hover,
        &:focus {
            fill: ${({theme}) => theme.colors.brand};
        }

        &:not(:first-child) {

            @media (min-width: 400px) and (max-width: 859px) {
                margin-left: 8px;
            }
            @media (min-width: 860px) {
                margin-left: 20px;
            }
        }

        svg {
            width: var(--icon-size);
            height: var(--icon-size);
            fill: inherit;
        }
        &.close-session {
          @media (max-width: 400px) {
            margin-left: 10px;
          }
        }
    }

    .header-main__user {
        @media (max-width: 859px) {
            display: none;
        }
        @media (min-width: 860px) {
            flex-shrink: 0;
            margin-left: 26px;
        }
    }

    .header-main__user__button {
        fill: ${({theme}) => theme.colors.brandDark};

        &:not(:first-of-type) {
            margin-left: 8px;
        }
    }
`;
export default StyledHeaderMain;
