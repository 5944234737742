import React from "react";
import PropTypes from "prop-types";
import StyledSpinnerLoader from "./SpinnerLoader.styled";
import { selectedTheme } from "../../config/constants";

const SpinnerLoader = React.memo(({ size, color }) => {
  switch(selectedTheme) {
    case 'FlowerTheme':
      color = "var(--color-flowerBrandBright)";
    break;
    case 'BundleTheme':
      color = "var(--color-bundleColor)";
    break;
    default:
  };

  return (
    <StyledSpinnerLoader color={color} size={size}></StyledSpinnerLoader>
  );
},(prev,next)=>{
  return prev.size !== next.size || prev.color !== next.size
});

SpinnerLoader.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
};

SpinnerLoader.defaultProps = {
  size: 50,
};

export default SpinnerLoader;
