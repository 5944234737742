/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useLocation} from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cookie from "react-cookies";

// Local Imports
import {locationActions, globals, categoriesFunc} from '../../actions';
import {storeType, storeName, isKiosk} from "../../config/constants";
import useOutsideClick from "../../helpers/useOutsideClick";
import { locationPhonePT, featuredBrandPT, categoriesPT, discoversAllPT } from "../../config/prop-types"

// Component Imports
import HeaderMainFlowerStyled from "./HeaderMainFlower.styled";
import InputSearchAhead from "../InputSearchAhead/InputSearchAhead";
import SVGIcon from "../utility/SVGIcon";
import HeaderMainSecondaryFlower from "../HeaderMainSecondaryFlower/HeaderMainSecondaryFlower";
import ProfileDropdownFlower from "../ProfileDropdown/ProfileDropdownFlower/ProfileDropdownFlower";
import MainMenuFlower from "../MainMenu/MainMenuFlower/MainMenuFlower";
import MobileMenuFlower from "../MobileMenu/MobileMenuFlower/MobileMenuFlower";

const HeaderMainFlower = React.memo(props => {
    const {
        brandName,
        brandLogo,
        dispatch,
        cartCount,
        featuredBrand,
        categories,
        sessionClose,
        selectedTheme,
    } = props;

    const [menuOpen, setMenuOpen] = useState(false);
    const toggleNavMenu = () => {
        setMenuOpen(!menuOpen)
    }

    useEffect(() => {
        dispatch(locationActions.selectedLocation());
        dispatch(locationActions.locationInfo());
        dispatch(globals.cartCount());
        dispatch(categoriesFunc.featuredBrandsAll());
    }, []);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(globals.userInfo());
        }
    }, [])

    //Hidden menu when clicked off store panel
    const ref = useRef();
    useOutsideClick(ref, () => {
        if (menuOpen) setMenuOpen(false);
    });

    // Look up current route
    const currentRoute = useLocation();
    const formatPathName = (str) => {
        //Replace all "/" from pathname with space
        let lettersOnly = ('' + str).replace(/[/]/g, ' ');
        // Uppercase first letter of string
        let formatPath = lettersOnly.charAt(1).toUpperCase() + lettersOnly.slice(2);

        return formatPath;
    };
    const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));
    return (
        <HeaderMainFlowerStyled ref={ref}>
            <div className="header-main">
                <div className="header-main__wrap">
                    <MediaQuery maxWidth={859}>
                        <MobileMenuFlower
                            categories={categories}
                            featuredBrand={featuredBrand}
                            dispatch={dispatch}
                        />
                    </MediaQuery>

                    <h1 className="t-hidden">
                        {brandName} Online Cannabis Delivery
                        {currentRoute.pathname === "/" ?
                            ""
                            :
                            ` - ${formatPathName(currentRoute.pathname)}`
                        }
                    </h1>

                    <Link to="/" className="header-main__home">
                        {storeType === 'rebud' ?
                            <>
                                <SVGIcon name="flower-header-logo" className="header-main__logo"/>
                            </>
                            :
                            <img src={brandLogo} alt={brandName} className="header-main__logo-other"/>
                        }
                        <span className="t-hidden">{storeName} home</span>
                    </Link>

                    <button className="t-shop-flower header-main__link menu_btn" onClick={toggleNavMenu}>
                        SHOP
                        <SVGIcon name='flower-down-icon' className={`menu_btn__icon ${menuOpen ? "isOpen" : ""}`}/>
                    </button>

                    <div className="header-main__icons">
                        <InputSearchAhead
                            inputName="search_input"
                            placeholder="What are you looking for…"
                            className="header-main__search-ahead"
                        />

                        <div className="header-main__user">
                            <ProfileDropdownFlower className="header-main__user__button" dispatch={dispatch}/>
                        </div>
                        {cookie.load('HDeviceToken') && LoginStatus &&
                            <Link to="/notifications" className="header-main__icon-button notification-icon">
                                <SVGIcon name="notifications"/>
                            </Link>
                        }

                        <Link to="/cart" className="header-main__icon-button cart-icon">
                            <SVGIcon name="flower-cart-icon"/>
                            <span className="t-hidden">Cart</span>
                            <div
                                className="cart-count t-font-Moderat-Medium">{cartCount ? cartCount : localStorage.getItem("itemsCountInShoppingCart")}</div>
                        </Link>
                        {isKiosk && LoginStatus &&
                            <button data-testid="close-session" onClick={sessionClose}
                                    className="header-main__icon-button close-session">
                                <SVGIcon name="close_session"/>
                            </button>
                        }
                    </div>

                    <MainMenuFlower
                        isOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                        categories={categories}
                        featuredBrand={featuredBrand}
                    />
                </div>
            </div>

            {!isKiosk &&
                <HeaderMainSecondaryFlower selectedTheme={selectedTheme} />
            }

        </HeaderMainFlowerStyled>
    );
});
const mapStateToProps = ({
                             userAddress,
                             locationInfo,
                             cartCount,
                             featuredBrandAll,
                             categoriesAll,
                             personalityAll,
                             discoversAll,
                         }) => ({
    address: userAddress.address,
    locationPhone: locationInfo.locationPhone,
    cartCount: cartCount.count,
    featuredBrand: featuredBrandAll.featuredBrandsListAll,
    categories: categoriesAll.catListAll,
    personality: personalityAll.personalityListAll,
    discoversAll: discoversAll.discoversAll,
});

HeaderMainFlower.propTypes = {
    brandLogo: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    locationPhone: locationPhonePT,
    cartCount: PropTypes.number,
    featuredBrand: featuredBrandPT,
    categories: categoriesPT,
    sessionClose: PropTypes.func.isRequired,
    discoversAll: discoversAllPT,
};

export default connect(mapStateToProps)(HeaderMainFlower);
