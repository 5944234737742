import styled from "styled-components";

const StyledCheckboxAuthorizationBundle = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  font-size: var(--type-scale-14);
  color: ${({ theme }) => theme.colors.bundleGrey};
  
  input{
    display: none;
    
    &:checked + .check_icon .check_img{
        display: block
    }
  } 
  .check_icon{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 7px;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    border: solid 1px ${({ theme }) => theme.colors.gray2};
    background-color: ${({ theme }) => theme.colors.bundleWhite};
    
    .check_img{
        display: none;
        width: 8px;
        fill: var(--color-brand);
    }
  }
`;

export default StyledCheckboxAuthorizationBundle;
