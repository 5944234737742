import PropTypes from "prop-types";
import React from "react";
import SVGIcon from "../../utility/SVGIcon";
import Input from 'react-phone-number-input/input'
import DatePicker, { registerLocale } from "react-datepicker"
import en from "date-fns/locale/en-GB";
import StyledInputAuthorizationBundle from "./InputAuthorizationBundle.styled";
import StyledCheckboxAuthorizationBundle from "./CheckboxAuthorizationBundle.styled";
import { US } from "../../../utils/phoneNumberCode";

registerLocale('en', en);


const InputAuthorizationBundle = React.memo(props => {
    const {inputName, placeholder, type, required, errorMessage, warningMessage, phone, setPhone, focus, className, disabled, defaultValue, onChange, minDate, maxDate, country} = props;

    let Ret;
    switch (type) {
        case "checkbox":
            Ret = (
                <StyledCheckboxAuthorizationBundle className={className}>
                    <input className="t-form-input"
                           onChange={onChange}
                           name={inputName}
                           type={type}
                           required={required}
                           disabled={disabled}
                           defaultValue={defaultValue}
                    />
                    <span className="check_icon">
                        <SVGIcon name="check"  className="check_img" />
                    </span>
                    <span className="text_label">{placeholder}</span>
                </StyledCheckboxAuthorizationBundle>
            );
            break;
        case "phone":
            Ret = (
                <StyledInputAuthorizationBundle className={`${className} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}>
                    <Input
                        className={`t-form-input t-font-DINNextLTPro-Regular ${disabled?"disabled":''}`}
                        country={(country || country === "")  ? country : US}
                        name={inputName}
                        value={phone}
                        placeholder={placeholder}
                        onChange={setPhone}
                        required={required}
                        onFocus={focus}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        max={11}
                    />
                    <span className="placeHold">{placeholder}</span>
                    <span className="error-message">{errorMessage}</span>
                    {warningMessage &&
                        <span className="warning-message">{warningMessage}</span>
                    }
                </StyledInputAuthorizationBundle>
            );
            break;
        case "textarea":
            Ret = (
                <StyledInputAuthorizationBundle className={`${className} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}>
            <textarea
                className={`t-form-input t-font-DINNextLTPro-Regular ${disabled?"disabled":''}`}
                name={inputName}
                id="inputID"
                placeholder={placeholder}
                onChange={onChange}
                required={required}
                onFocus={focus}
                disabled={disabled}
                defaultValue={defaultValue}
            />
                    <span className="error-message">{errorMessage}</span>
                    {warningMessage &&
                        <span className="warning-message">{warningMessage}</span>
                    }
                </StyledInputAuthorizationBundle>
            );
            break;
        case "datePick":
            Ret = (
                <StyledInputAuthorizationBundle className={`${className} t-font-DINNextLTPro-Regular ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}>
                    <DatePicker
                        selected={defaultValue}
                        minDate={minDate}
                        maxDate={maxDate}
                        onChange={date => onChange(date)}
                        name={inputName}
                        placeholderText={placeholder}
                        required={required}
                        disabled={disabled}
                        popperClassName="some-custom-class"
                        popperPlacement="top-end"
                        dateFormat="MM/dd/yyyy"
                        showMonthDropdown
                        showYearDropdown
                        autocomplete='off'
                        popperModifiers={{
                            offset: {
                                enabled: true,
                                offset: "5px, 10px"
                            },
                            preventOverflow: {
                                enabled: true,
                                escapeWithReference: false,
                                boundariesElement: "viewport"
                            }
                        }}
                    />
                    <span className="placeHold t-font-DINNextLTPro-Regular">{placeholder}</span>
                    <span className="error-message">{errorMessage}</span>
                    {warningMessage &&
                        <span className="warning-message">{warningMessage}</span>
                    }
                </StyledInputAuthorizationBundle>
            );
            break;
        default:
            Ret = (
                <StyledInputAuthorizationBundle className={`${className} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}>
                    <input className={`t-form-input t-font-DINNextLTPro-Regular ${disabled?"disabled":''}`}
                           {...props}
                           name={inputName}
                           type={type}
                           placeholder={placeholder}
                           required={required}
                           onFocus={focus}
                           disabled={disabled}
                           defaultValue={defaultValue}
                           onChange={onChange}
                           autoComplete="off"
                    />
                    <span className="placeHold">{placeholder}</span>
                    <span className="error-message">{errorMessage}</span>
                    {warningMessage &&
                        <span className="warning-message">{warningMessage}</span>
                    }
                </StyledInputAuthorizationBundle>
            )
            break;
    }
    return Ret;
});

InputAuthorizationBundle.propTypes = {
    type: PropTypes.string.isRequired,
    inputName: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    minDate: PropTypes.object,
    warningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    onFocus: PropTypes.func,
    className: PropTypes.string
};
InputAuthorizationBundle.defaultProps = {
    required: true
};
export default InputAuthorizationBundle;
