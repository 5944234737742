import { userService } from '../services';
import {
  BRAND_LOADING,
  GET_BRAND_PRODUCTS,
  LOAD_MORE_BRANDS
} from "../config/actionTypes";
import qs from "qs";

export const brandActions = {
  getBrandProducts,
  loadMoreBrands
};

function loadMoreBrands(params) {
  return dispatch => {
    dispatch(requestLoader());
    return userService.PostInfo('/search', params)
      .then(
        result => {
          dispatch(success(result));
          dispatch(successLoader());
        },
        () =>{
          dispatch(failureLoader());
        }
      );
  };
  function requestLoader() { return { type: 'REQUEST' } }
  function successLoader() { return { type: 'SUCCESS' } }
  function failureLoader() { return { type: 'FAILURE' } }
  function success(result) { return { type: LOAD_MORE_BRANDS, result } }
}

function getBrandProducts(queryParams, urlParams, page) {
  let endpoint = '/brands/:uri_param';
  if (queryParams) {
    endpoint += '?' + qs.stringify(queryParams)
  }
  if (urlParams) {
    Object.keys(urlParams).forEach(k => {
      endpoint = endpoint.replace(':' + k, urlParams[k]);
    });
  }
  return dispatch => {
    dispatch(brandLoading());
    userService.GetInfo(endpoint, '')
      .then( (data) => userService.PostInfo("/search", { brand:[data.uri_param], page: page }).then((result) => {
          return dispatch(success({
            ...result,
            ...data
          }))
        }
      ))
  };
  function brandLoading() { return { type: BRAND_LOADING, loading: true } }
  function success(result) { return { type: GET_BRAND_PRODUCTS, result, loading: false } }
}