import styled from "styled-components";

const StyledInputAuthorizationBundle = styled.label`
  position: relative;
  padding-bottom: 2rem;
  margin-top: 1rem;
  display: block;
  &.error{
    .placeHold {
        color: ${({ theme }) => theme.colors.errorForm};
    }
    input{
        border: solid 1px ${({ theme }) => theme.colors.errorForm} !important;
        color: ${({ theme }) => theme.colors.errorForm};
      &:focus {
        &::placeholder {
          color: transparent;
        }

        & + .placeHold {
          color: ${({theme}) => theme.colors.errorForm}
        }
      }
    }
    .error-message{
        color: ${({ theme }) => theme.colors.errorForm};
        font-size: var(--type-scale-14);
        padding: 3px 0 0 10px;
        display: block;
    }
  }
  &.warning{
    .placeHold {
        color: ${({ theme }) => theme.colors.error};
    }
    input{
        border: solid 1px ${({ theme }) => theme.colors.error} !important;
        color: ${({ theme }) => theme.colors.error};
    }
    .warning-message{
        color: ${({ theme }) => theme.colors.error};
        font-size: var(--type-scale-14);
        padding: 3px 0 0 10px;
        display: block;
    }
  }
  &.dataPicker_default {
    .react-datepicker-wrapper {
      width: 100%;
      input {
      font-size: 16px;
        &:disabled {
          border: solid 1px ${({ theme }) => theme.colors.bundleWhite};
          background-color: ${({ theme }) => theme.colors.bundleWhite};
        }
      }
    }
  }
  .placeHold {
    color: ${({ theme }) => theme.colors.bundleWhite};
    font-size: 14px;
    position: absolute;
    top: -18px;
    left: 2px;
    transition: all 0.2s ease-in-out;
  }
  input{
    padding: 1.375rem 16px;
    width: 100%;
    height: 64px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    border: solid 1px ${({ theme }) => theme.colors.bundleGrey};
    background-color: ${({ theme }) => theme.colors.bundleWhite};
    outline: none;

    &::placeholder {
     color: ${({ theme }) => theme.colors.bundleBlack};
     transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
        border-color: ${({ theme }) => theme.colors.brand}
    }
    &:focus {
        &::placeholder{
            color: transparent;
        }
        & + .placeHold{
          color: ${({ theme }) => theme.colors.bundleWhite}
        }
    }
    &.disabled {
      border: solid 1px ${({ theme }) => theme.colors.bundleWhite};
      background-color: ${({ theme }) => theme.colors.bundleWhite};
    }
  }
  textarea{
    padding: 1.375rem 16px;
    width: 100%;
    height: 100px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
    border: solid 1px ${({ theme }) => theme.colors.bundleGrey};
    background-color: ${({ theme }) => theme.colors.bundleWhite};
    outline: none;
    resize: vertical;
    font-family: Arial,serif;
    &::placeholder {
     color: ${({ theme }) => theme.colors.bundleBlack};
     transition: all 0.2s ease-in-out;
    }

    &:hover,
    &:focus {
        border-color: ${({ theme }) => theme.colors.brand}
    }
    &:focus {
        &::placeholder{
            color: transparent;
        }
        & + .placeHold{
          color: ${({ theme }) => theme.colors.bundleBlack}
        }
    }
    &.disabled {
      border: solid 1px ${({ theme }) => theme.colors.bundleWhite};
      background-color: ${({ theme }) => theme.colors.bundleWhite};
    }
  }  

`;

export default StyledInputAuthorizationBundle;
