export const apiUrl = process.env.REACT_APP_API_URL;
export const storeName = process.env.REACT_APP_STORE_NAME;
export const storeDomain = process.env.REACT_APP_STORE_DOMAIN;
export const storeTitle = process.env.STORE_TITLE;
export const ga4Key = process.env.REACT_APP_GA4_KEY;
export const gtmKey = process.env.REACT_APP_GTM_KEY;
export const themeName = process.env.THEME_NAME ? process.env.THEME_NAME : "";
export const storeType = process.env.REACT_APP_STORE_TYPE;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const isKiosk = process.env.REACT_APP_IS_KIOSK === '1';
const metas = process.env.REACT_APP_METAS;
export const metaData = metas ? JSON.parse(metas) : [];
export const yotpoGUID = process.env.REACT_APP_YOTPO_GUID;
export const selectedTheme = process.env.REACT_APP_THEME;
