import React from "react";
import HeaderDeliveryInputEverscore from "../HeaderDeliveryInputEverscore/HeaderDeliveryInputEverscore";
import StyledHeaderMainSecondaryEverscore from "./HeaderMainSecondaryEverscore.styled";

const HeaderMainSecondaryEverscore = React.memo(() => {
  return (
    <StyledHeaderMainSecondaryEverscore>
      <div className="header-main__wrap">
        <HeaderDeliveryInputEverscore />
      </div>
    </StyledHeaderMainSecondaryEverscore>
  );
});

export default HeaderMainSecondaryEverscore;
