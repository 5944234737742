import {authHeader, history} from '../helpers';
import { apiUrl } from "../config/constants";
import * as toast from "../components/utility/toast";

export const userService = {
    login,
    logout,
    register,
    PostInfo,
    PostFile,
    PatchInfo,
    GetInfo,
    PutInfo,
    DeleteInfo
};
const config= '';

function login( url, user ) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };
    return fetch(`${apiUrl}${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
           if (user.token ) {
                localStorage.setItem('LoginStatus', "true");
                localStorage.setItem('token', JSON.stringify(user.token));
            }
            return user;
        });
}
function logout(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${config}${url}`, requestOptions)
        .then(handleResponse)
        .then(() => {
            localStorage.removeItem('token');
            localStorage.setItem('LoginStatus', "false")
        });
}
function register( url, user ) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(user)
    };
    return fetch(`${apiUrl}${url}`, requestOptions)
        .then(handleResponse)
        .then(user => {
            if (user.token ) {
                localStorage.setItem('LoginStatus', "true");
                localStorage.setItem('token', JSON.stringify(user.token));
            }
            return user;
        });
}
function PostInfo(url, data) {
    const requestOptions = {
        method: 'POST',
        headers:  authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse)
}

function PatchInfo(url, data) {
    const requestOptions = {
        method: 'PATCH',
        headers:  authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse)
}

function PostFile(url, data) {
    const requestOptions = {
        method: 'POST',
        headers:  authHeader(true),
        body: data
    };
    return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse)
}

function GetInfo(url, data, header) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(false, header),
    };
    return fetch(`${apiUrl}${url}${data}`, requestOptions).then(handleResponse)
}
function PutInfo(url, data) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse)
}
function DeleteInfo(url, data) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
    return fetch(`${apiUrl}${url}`, requestOptions).then(handleResponse)
}
function handleResponse(response) {
    return response.text().then(text => {
        if(response.status !== 502){
            let data = text && (typeof text === 'string') && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    localStorage.setItem('LoginStatus', "false");
                    window.location.reload();
                }
                else if(response.status === 400 || response.status === 404){
                    return Promise.reject(data);
                }
                else {
                    toast.error({ text: data.message })
                    if(data && data.type && data.type === "out_of_stock") {
                        history.push('/cart')
                    }
                    return Promise.reject({errorCode: 403});
                }
            }
            return data;
        }
        else  {
            toast.error({ text: 'Server time out problem' })
            return Promise.reject({errorCode: 502});
        }
    });
};