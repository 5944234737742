import styled from "styled-components";

const StyledModalVerification = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform : ${props => props.isShown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity : ${props => props.isShown ? '1' : '0'};
  z-index: 10;
  filter: blur(0) !important;

  .verification {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 727px;
    min-height: 497px;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 12;
    
    @media (max-width: 799px) {
      padding: 10.11px 0 25.35px;
      max-width: 360px;
      min-height: unset;
      background-repeat: round;
    }
    @media (min-width: 800px) {
      padding: 23.38px 0 34.23px;

    }
  }
  
  .verification__logo {
    align-self: center;
    
    @media (max-width: 799px) {
      width: 140px;
      height: 64px;
    }
    @media (min-width: 800px) {
      margin: 0 0 39.47px;
      width: 164.04px;
      height: 84.57px;
    }
  }

  .verification__heading {
    color: ${({theme}) => theme.colors.brandDark};

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 1rem;
    }
  }
  
  .verification__age {
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    
    &.is-disabled {
      color: ${({theme}) => theme.colors.gray4};
    }
  }
  
  .verification__yes__button {
    margin-right: 18.28px;
    padding: 8px 31px;
    width: 141.77px;
    color: ${({ theme }) => theme.colors.gray4};
    @media (max-width: 799px) {
      margin-right: 15.88px;
      font-size: var(--type-scale-14);
      width: 111.95px;
      padding: 8px 31px;
    }
  }

  .verification__button {
    padding: 8px 31px;
    line-height: normal;
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.colors.brandDark};
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.gray9};
    color: ${({ theme }) => theme.colors.gray4};
    font-size: var(--type-scale-18);
    font-weight: var(--weight-bold);
    text-align: center;
    flex-shrink: 0;
    transition: var(--button-transition);
    text-decoration: none;
    width: 141.77px;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.gray4};
      color: ${({ theme }) => theme.colors.white};
    }
    
    &.is-disabled {
      background-color: transparent;
      border-color: ${({ theme }) => theme.colors.gray4};
      color: ${({ theme }) => theme.colors.gray4};
      cursor: default;
    }

    @media (max-width: 799px) {
      font-size: var(--type-scale-14);
      width: 111.95px;
      padding: 8px 31px;
    }
  }

  .verification__form {
    display: flex;
    flex-direction: column;
    max-width: 590px;
    width: 100%;

    @media (max-width: 799px) {
      margin-bottom: 40.53px;
      margin-top: 28.09px;
      max-width: 290px;
    }
    @media (min-width: 800px) {
      margin-bottom: 75.58px;
    }
  }

  .verification__form__label {
    width: 100%;  
    position: relative;
    color: ${({ theme }) => theme.colors.gray4};
  }
  
  .verification__form__age-heading {
    margin-bottom: .75rem;
    color: inherit;

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: .75rem;
    }
  }
  
  .verification__form__label-text {
    margin-bottom: 25.3px;
    font-size: 23px;
    font-weight: var(--weight-bold);
    line-height: 23px;
    text-align: center;
    @media (max-width: 799px) {
      margin-bottom: 18.86px;
      font-size: 20px;
      line-height: 23px;
    }
  }

  .verification__form__label-text-second {
    margin-bottom: 19.63px;
    font-size: 18px;
    line-height: 21.6px;
    text-align: center;
    @media (max-width: 799px) {
      margin-bottom: 10.96px;
      line-height: 15.6px;
      font-size: 13px;
      width: calc(100% - 10px);
      letter-spacing: inherit;
    }
  }

  .first {
    margin: 0;
  }

  .verification__form__age-copy {
    color: ${({ theme }) => theme.colors.gray4};
    font-weight: var(--weight-bold);

    @media (max-width: 799px) {
      margin-bottom: 23.09px;
    }
    @media (min-width: 800px) {
      margin-bottom: 33.37px;
    }
  }

  .verification__footnote {
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.brandDark};

    .t-link {
      color: inherit;
    }
    .t-link:hover,
    .t-link:focus {
      color: ${({theme}) => theme.colors.brand};
    }
  }
  .not_deliv{
    margin: 3px 0;
    font-size: 12px;
  }

  .verification__input {
    background-color: transparent;
    border-radius: 0;
    border_bottom: 1px solid ${({ theme }) => theme.colors.gray4};
    transition: var(--button-transition);
    padding-right: 40px;
    line-height: 19.2px;
    font-family: var(--font-NHaasGroteskDSPro-45Lt)

    &:hover,
    &:focus {
      border-color: ${({theme}) => theme.colors.brand};
    }

    @media (max-width: 799px) {
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  .verification__form__address-input {
    position: relative;
  }
  .verification__form__ip-address-input {
    margin-top: 15px;
    .placeHold{
      display: none;
    }
    .verification__form__label-text{
      color: ${({theme}) => theme.colors.brandDark};
    }
    input{
      border-radius: 0;
    }
  }
  .verification-delivery_address__clear {
    border: none;
    outline: none;
    right: 0;
    top: 0.625rem;
    width: 16px;
    height: 16px;
    position: absolute;
    align-self: center;

    svg {
      fill: ${({ theme }) => theme.colors.gray4};
      width: 12.54px;
      height: 12.81px;
    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
    @media (max-width: 799px) {
      top: 0.325rem;
    }
  }

  .buttons-container {
    @media (max-width: 799px) {
      display: flex;
      justify-content: center;
    }
    @media (min-width: 800px) {
      display: block;
    }
  }
`;

export default StyledModalVerification;
