import PropTypes from "prop-types";
import React from "react";
import StyledSpinner from "./Spinner.styled";
import { selectedTheme } from "../../config/constants";

const Spinner = React.memo(({ className, size, color ,margin, border }) => {

  switch(selectedTheme) {
    case 'FlowerTheme':
      color = "var(--color-flowerBrandBright)";
    break;
    case 'BundleTheme':
      color = "var(--color-bundleColor)";
    break;
    default:
  };

  return (
    <StyledSpinner className={className} color={color} size={size}>
      <span className="loader"></span>
    </StyledSpinner>
  );
},(prev,next)=>{
  return prev.size !== next.size || prev.color !== next.size
});

Spinner.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};

Spinner.defaultProps = {
  size: 50,
};

export default Spinner;
