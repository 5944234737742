import styled from "styled-components";

const FooterMain = styled.footer`
  .footer-wrap {
    ${({simple, theme: { colors }}) => simple ? "" : "background-color: " + colors.brandLight};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;

    @media (max-width: 859px) {
      padding: 46.37px 16px;
    }
    @media (min-width: 860px) {
      padding: 50.42px 32px;
    }
  }

  .footer-home__heading {
    margin-top: 2.5rem;
    color: ${({theme}) => theme.colors.brandDark};
  }

  .logo-container {
    display: flex;
    justify-content: center;
  }
  
  .footer-home__logo {
    margin-top: 43.92px;
    width: 207.7px;
    height: 107.08px;
    @media (max-width: 599px) {
      margin-top: 33.99px;
      width: 139.28px;
      height: 71.81px;
    }
  }

  .footer-home__links {
    width: 100%;
    
    @media (max-width: 599px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: 600px) and (max-width: 859px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 859px) {
      display: grid;
      grid-gap: 4px 0;
      padding: 0px 44px;
    }
    @media (min-width: 860px) {
      max-width: 800px;
      display: flex;
      ${'' /* justify-content: space-between; */}
      ${'' /* TODO - back to space-between after returning About and FAQ sections */}
      justify-content: center;
    }
  }

  .one {
    @media (max-width: 859px) {
      order: 1;
    }
  }

  .two {
    @media (max-width: 859px) {
      order: 3;
    }
  }

  .three {
    @media (max-width: 859px) {
      order: 5;
    }
  }

  .four {
    @media (max-width: 859px) {
      order: 4;
    }
  }

  .five {
    @media (max-width: 859px) {
      order: 2;
    }
  }

  .footer-home__link {
    a {
      color: ${({theme}) => theme.colors.brandDark};
      font-family: var(--font-NHaasGroteskDSPro-65Md);

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      @media (min-width: 860px) {
        padding-bottom: 50.07px;
      }
    }
    
    @media (min-width: 860px) {
      display: inline-flex;

      &:not(:last-of-type) {
        margin-right: 40px;
      }
    }
  }

  .footer-home__socials {
    padding-right: 32px;
    padding-left: 32px;
    display: flex;
    width: 100%;
    max-width: 900px;
    justify-content: center;
  }

  .footer-home__social {
    display: inline-flex;
    
    a {
      padding: 12px;
      display: flex;
    }

    svg {
      width: 24px;
      height: 24px;
      display: flex;
      flex-shrink: 0;
      fill: ${({theme}) => theme.colors.brandDark};
      transition: var(--button-transition);
    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brand}
      }
    }
  }

  .youtube {
    a {
      padding: 7px 12px 6px;
    }
  }

  .youtube {
    svg {
      width: 29px;
      height: 30px;
    }
  }

  .facebook {
    a {
      padding: 11px 12px;
    }
  }

  .facebook {
    svg {
      width: 27px;
      height: 27px;
    }
  }

  .footer-home__affiliate,
  .footer-home__copywrite {
    margin-top: 36px;
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    
    @media (min-width: 860px) {
      text-align: center;
    }
  }

  .footer-home__affiliate {
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  .footer-home__license {
    margin-top: 0.5rem;
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
  }

  .stay-connected {
    font-weight: normal;
    color: ${({theme}) => theme.colors.brandDark};
    font-family: var(--font-NHaasGroteskDSPro-65Md);
    font-weight: bold;
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 19.2px;
    @media (max-width: 859px) {
      margin-top: 44.56px;
    }
  }
`;

export default FooterMain;
