import {
    GET_USER_INFO,
    GET_STORE_INFO,
    GET_USER_ADDRESS,
    GET_LOCATION_INFO,
    GET_CART_COUNT,
    GET_PROMO,
    GET_WELCOME_PROMO,
    GET_LOCATION_INFO_LOADING,
    GET_NOTIFICATIONS,
    GET_USER_NFT,
} from '../config/actionTypes';

export function userInfo(state = {}, action) {
    switch (action.type) {
        case GET_USER_INFO:
            return {
                user: action.user,
            };
        default:
            return state
    }
}
export function storeInfo(state = {}, action) {
    switch (action.type) {
        case GET_STORE_INFO:
            return {
                storeInfo: action.storeInfo,
            };
        default:
            return state
    }
}
export function userAddress(state = {}, action) {
    switch (action.type) {
        case GET_USER_ADDRESS:
            return {
                address: action.address,
            };
        default:
            return state
    }
}
export function locationInfo(state = {}, action) {
    switch (action.type) {
        case GET_LOCATION_INFO:
            return {
                locationPhone: action.locationPhone,
                loading: action.loading,
            };
        case GET_LOCATION_INFO_LOADING:
            return {
                locationPhone: action.locationPhone,
                loading: action.loading,
            };
        default:
            return state
    }
}
export function cartCount(state = {}, action) {
    switch (action.type) {
        case GET_CART_COUNT:
            return {
                count: action.count,
                loading: false,
            };
        default:
            return state
    }
}

export function promotions(state = {}, action) {
    switch (action.type) {
        case GET_PROMO:
            return {
                promo: action.promo,
            };
        default:
            return state
    }
}

export function welcomePromo(state = {}, action) {
    switch (action.type) {
        case GET_WELCOME_PROMO:
            return {
                welcomePromo: action.promo,
            };
        default:
            return state
    }
}

export function notifications(state = {}, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return {
                notifications: action.notifications,
            };
        default:
            return state
    }
}

export function userNFT (state = {}, action) {
    switch (action.type) {
        case GET_USER_NFT:
            return {
                userNFT: action.userNFT,
            };
        default:
            return state
    }
}