import React, {Fragment, lazy} from 'react';
import { Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from "react-toastify";
import { ScrollContext } from "react-router-scroll-4";
import 'react-toastify/dist/ReactToastify.css';
import { store, history } from './helpers';
import { selectedTheme } from "./config/constants";
import defaultTheme from './styles/themes/DefaultTheme';
import greenTheme from './styles/themes/GreenTheme';
import bundleTheme from './styles/themes/BundleTheme';
import flowerTheme from "./styles/themes/FlowerTheme";
import eversoreTheme from "./styles/themes/EverscoreTheme";
import rebudConfig from "./config/marketConfigs/rebudConfig";
import flowerConfig from "./config/marketConfigs/flowerConfig";
import everscoreConfig from "./config/marketConfigs/everscoreConfig";
import bundleConfig from "./config/marketConfigs/bundleConfig";
import AppContainer from "./containers/AppContainer/AppContainer";
import { Popup }  from './components/popups/Popup';
import AppStyled from './App.styled'

const isBundleTheme = selectedTheme === "BundleTheme";

/* import PwaIos from "./components/PwaIos/PwaIos"; */
/* import ModalPWA from "./components/ModalPWA/ModalPWA"; */

const shouldUpdateScroll = (prevRouterProps, { location, history }) => (
    prevRouterProps && location.pathname !== prevRouterProps.location.pathname
);
export const ThemePramsContext = React.createContext({});

function App() {
  // Hide code until new requirments are added to jira and future sprint,
  // New logic will be to only show PWA modal for users that have made their first order and then return to rebud
/*   const [showPWAModal, setShowPWAModal] = useState(false);
  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }
  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

  // Checks if should display install popup notification:
  if (!localStorage.getItem('IosBanner') && !showPWAModal && isIos() && !isInStandaloneMode()) {
    setShowPWAModal(true);
  }
  window.addEventListener('appinstalled', (evt) => {
    setShowPWAModal(false)
    localStorage.setItem('IosBanner', "close");
  });
  const closePwaModal = (val) => {
    setShowPWAModal(val)
    localStorage.setItem('IosBanner', "close");
  } */

  let theme;
  let selectedConfig
  switch (selectedTheme) {
    case "FlowerTheme":
      theme = flowerTheme;
      selectedConfig = flowerConfig;
      break;
    case "BundleTheme":
      theme = bundleTheme;
      selectedConfig = bundleConfig;
      break;
    case "EverscoreTheme":
      theme = eversoreTheme;
      selectedConfig = everscoreConfig;
      break;
    case "GreenTheme":
      theme = greenTheme;
      selectedConfig = rebudConfig;
      break;
    default:
      selectedConfig =  rebudConfig;
      theme = defaultTheme;
  };
  return (
    <ThemeProvider theme={theme}>
      <ThemePramsContext.Provider value={selectedConfig}>
      <Provider store={store}>
        <Router history={history} >
          <ScrollContext shouldUpdateScroll={shouldUpdateScroll}>
            <Fragment>
              <ToastContainer
                hideProgressBar
                autoClose={4000}
                closeButton={false}
                position="top-right"
              />
              <Popup />
              {/* <ModalPWA shown={showPWAModal} onChange={closePwaModal}>
                <PwaIos />
              </ModalPWA> */}
              <AppStyled isBundleTheme={isBundleTheme} className="App">
                <AppContainer isBundleTheme={isBundleTheme} />
              </AppStyled>
            </Fragment>
          </ScrollContext>
        </Router>
      </Provider>
      </ThemePramsContext.Provider>
    </ThemeProvider>
  );
}

export default App;
