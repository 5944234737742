import flowerTheme from "../../styles/themes/FlowerTheme";
import rebudTheme from "../../styles/themes/DefaultTheme";
import everscoreTheme from "../../styles/themes/EverscoreTheme";

const configFlower = {
    contactUsConfig: {
        background:'',
        colorFonts:'',
        classMainFont: '',
        classNameTitleFont: 't-font-Moderat-Bold',
        classNameDescription: 't-font-Moderat-Medium',
        color: 'brandDark',
        pstText: 'PST',
        mainPageHeadClass1: 't-shelf-heading-flower',
        mainPageHeadClass2: 't-font-Moderat-Mono-Regular',
        mainPageHeadClass3: 't-font-Moderat-Medium',
        mainPageHeadClass4: 't-font-Moderat-Mono-Regular',
        contactFormFont: 'Moderat-Medium, sans-serif',
        contactFormClass: 't-shelf-heading-flower',
        buttonFont: 'Moderat-Bold, sans-serif',
        buttonColor: "",
    },
    checkoutConfig: {
        headingClass: 't-shelf-heading-flower',
        classNameFont1: 't-font-Moderat-Medium',
        fontName1:  't-font-Moderat-Mono-Regular',
        iconName1:  'checked-flower',
        iconName2:  'check-flower',
        steps: {
            font1: 't-font-Moderat-Mono-Regular',
            font2: 't-shelf-heading-flower',
            font3: 't-font-Moderat-Mono-Regular',
            font4: 't-font-Moderat-Bold',
            font5: 't-font-Moderat-Medium',
            font6: 't-font-Moderat-Medium',
            font7: 't-font-Moderat-Mono-Regular',
            color1: flowerTheme.colors.gray2,
            color2: '',
            color3: flowerTheme.colors.brandDark,
            color4: flowerTheme.colors.gray3,
            classNameFont1: '',

        },
        simpleModal: {
            color1: flowerTheme.colors.white,
            color2: ''
        },
        mainFont1: 't-description-flower-light',
        mainFont2: 't-description-flower-light',
        mainFont3: 't-font-Moderat-Medium',
        mainFont4: 't-font-Moderat-Medium',
        iconName3: 'delivery-circle-active-flower',
        mainFont5: 't-paragraph-flower',
        iconName4: 'delivery-normal-box-flower',
        mainFont6: 't-description-flower',
        dark: true,
        mainFont7: 't-font-Moderat-Mono-Regular',
        mainFont8: 't-font-Moderat-Medium',
        mainFont9: 't-font-Moderat-Medium',
        mainText1: 'HOF',
        inputAuth1: {
            font: '',
            color1: '#f5f5f5',
            color2: flowerTheme.colors.gray3,
            color3: flowerTheme.colors.gray2,
            color4: flowerTheme.colors.white,
            color5: flowerTheme.colors.brandDark,
            color6: flowerTheme.colors.gray1,
            color7: flowerTheme.colors.gray3,
            color8: flowerTheme.colors.brandDark,
            font1: 'Moderat-Medium, sans-serif',
            error: flowerTheme.colors.warning,
            color9: rebudTheme.colors.gray3,
            color10: "#fff",
            color11: flowerTheme.colors.gray3
        },
        dropUpload: {
            color1: '',
            color2: flowerTheme.colors.gray1,
            color3: '',
            color4: flowerTheme.colors.brand,
            iconName1: 'img-upload-flower',
            error: flowerTheme.colors.error,
            font: "t-paragraph-flower",
            errorText: rebudTheme.colors.gray4,
        },
        themeLocationSearchInput: {
            font1: 't-font-Moderat-Medium t-form-input',
            font2: 't-font-Moderat-Mono-Regular',
            font3: 't-font-Moderat-Medium',
            zipColor1: flowerTheme.colors.white,
            zipColor2: flowerTheme.colors.gray3,
            padding: '1.375rem 16px',
            height1: '36px',
            height2: '26px',
            borderRadius: '0',
            border: `none`,
            borderBottom: `solid 1px ${flowerTheme.colors.flowerBrandDark}`,
            font4: "Moderat-Mono",
            color1: '#ffffff',
            color2: ''
        },
        iconName5: 'store-pickup',
        checkoutStyles: {
            color1: '',
            font1: '',
            color2: '',
            color3: rebudTheme.colors.gray4,
            color4: rebudTheme.colors.brandDark,
            color5: rebudTheme.colors.white,
            color6: rebudTheme.colors.brand,
            color7: rebudTheme.colors.white,
            color8: '',
            color9: '',
            color10: '',
            color11: rebudTheme.colors.gray3,
            color12: rebudTheme.colors.gray3,
            color13: rebudTheme.colors.gray2,
            color14: rebudTheme.colors.gray1,
            color15: rebudTheme.colors.brand,
            color16: rebudTheme.colors.white,
            color17: rebudTheme.colors.black,
            color18: rebudTheme.colors.brandDark,
            color19: '',
            ontSize1: '',
            color20:  rebudTheme.colors.gray3,
            color21: rebudTheme.colors.brandDark,
            color22: '',
            color23: rebudTheme.colors.brandDark,
            color24: rebudTheme.colors.brand,
            color25: '',
            color26: rebudTheme.colors.gray3,
            color27: rebudTheme.colors.gray4,
            color28: '',
            color29: rebudTheme.colors.gray1,
            color30: rebudTheme.colors.gray2,
            color31: rebudTheme.colors.black,
            color32: rebudTheme.colors.white,
            margin: '0 auto',
            overflow: 'initial',
            color33: '#757575',
            color34: rebudTheme.colors.brandDark,
            error: rebudTheme.colors.warning,
            maxWidth: '167px',
            font2: 'Moderat-Mono',
        },
        summaryConfig: {
            font1: 't-font-Moderat-Bold',
            font2: 't-font-Moderat-Medium',
            font3: 't-font-Moderat-Mono-Regular',
            font4: 't-paragraph-flower',
        },
        priceItemColor: '#222'
    }
}
export default configFlower;