import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { loading } from './request.reducer';
import * as alertsReducers from './alert.reducer';
import * as modalsReducers from './modal.reducer';
import * as globalReducers from './global.reducer';
import * as autocompleteReducer from './autocomplete.reducer';
import * as categoriesReducers from './categories.reducer';
import * as brandReducers from './brands.reducer';
import * as userReducers from './user.reducer';
import * as orderReducers from './orders.reducer';
import * as searchReducers from './search.reducer';
import * as cartReducers from './cart.reducer';
import * as homeReducers from './home.reducer';
import * as checkoutReducers from './checkout.reducer';
import * as productReducers from './product.reducer';
import * as documentsReducer from './documents.reducer';
import * as blogReducers from './blog.reducer';
import * as aeropayReducers from './aeropay.reducer';
import * as yotpoDataReducer from './yotpoData.reducer';
import * as locationsReducers from './locations.reducer';
import * as landingReducers from './landing.reducer';
import * as requestCheckout from './requestCheckout.reducer';


const rootReducer = combineReducers({
      authentication,
      loading,
      ...modalsReducers,
      ...alertsReducers,
      ...brandReducers,
      ...userReducers,
      ...orderReducers,
      ...searchReducers,
      ...cartReducers,
      ...globalReducers,
      ...autocompleteReducer,
      ...homeReducers,
      ...categoriesReducers,
      ...checkoutReducers,
      ...productReducers,
      ...documentsReducer,
      ...blogReducers,
      ...aeropayReducers,
      ...yotpoDataReducer,
      ...locationsReducers,
      ...landingReducers,
      ...requestCheckout
});

export default rootReducer;