import PropTypes from "prop-types";
import React, {useContext} from "react";
import StyledInputAuthorization from "./InputAuthorization.styled";
import StyledCheckboxAuthorization from "./CheckboxAuthorization.styled";
import SVGIcon from "../../utility/SVGIcon";
import Input from 'react-phone-number-input/input'
import DatePicker, { registerLocale } from "react-datepicker"
import en from "date-fns/locale/en-GB";
import { US} from "../../../utils/phoneNumberCode";
import {ThemePramsContext} from "../../../App";

registerLocale('en', en);


const InputAuthorization = React.memo(props => {
    const {
        inputName,
        placeholder,
        type,
        required,
        errorMessage,
        warningMessage,
        phone,
        setPhone,
        focus,
        className,
        disabled,
        defaultValue,
        onChange,
        minDate,
        maxDate,
        country
    } = props;
    let Ret;
    const {checkoutConfig:{inputAuth1}} = useContext(ThemePramsContext);
    switch (type) {
        case "checkbox":
            Ret = (
                <StyledCheckboxAuthorization
                    className={className}
                    inputAuth={inputAuth1}
                >
                    <input className={`${className} t-form-input`}
                           onChange={onChange}
                           name={inputName}
                           type={type}
                           required={required}
                           disabled={disabled}
                           defaultValue={defaultValue}

                    />
                    <span className="check_icon">
                        <SVGIcon name="check"  className="check_img" />
                    </span>
                    <span className="text_label">{placeholder}</span>
                </StyledCheckboxAuthorization>
            );
            break;
        case "phone":
            Ret = (
                <StyledInputAuthorization
                    className={`${className} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}
                    inputAuth={inputAuth1}
                >
                    <Input
                        className={`t-form-input ${className} ${inputAuth1?.font} ${disabled?"disabled":''}`}
                        country={(country || country === "")  ? country : US}
                        name={inputName}
                        value={phone}
                        placeholder={placeholder}
                        onChange={setPhone}
                        required={required}
                        onFocus={focus}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        max={11}
                    />
                    <span className="placeHold">{placeholder}</span>
                    <span className="error-message">{errorMessage}</span>
                    {warningMessage && 
                        <span className="warning-message">{warningMessage}</span>
                    }
                </StyledInputAuthorization>
            );
         break;
        case "text":
            Ret = (
                <StyledInputAuthorization
                    className={`${className} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}
                    inputAuth={inputAuth1}
                >
                    <input
                        className={`t-form-input ${className} ${inputAuth1?.font} ${disabled?"disabled":''}`}
                        name={inputName}
                        placeholder={placeholder}
                        onChange={onChange}
                        required={required}
                        onFocus={focus}
                        disabled={disabled}
                        defaultValue={defaultValue}
                    />
                    <span className="placeHold">{placeholder}</span>
                    <span className="error-message">{errorMessage}</span>
                    {warningMessage &&
                        <span className="warning-message">{warningMessage}</span>
                    }
                </StyledInputAuthorization>
            );
            break;
      case "textarea":
        Ret = (
          <StyledInputAuthorization
              className={`${className} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}
              inputAuth={inputAuth1}
          >
            <textarea
              className={`t-form-input ${className} ${inputAuth1?.font} ${disabled?"disabled":''}`}
              name={inputName}
              placeholder={placeholder}
              onChange={onChange}
              required={required}
              onFocus={focus}
              disabled={disabled}
              defaultValue={defaultValue}
            />
            <span className="error-message">{errorMessage}</span>
            {warningMessage &&
            <span className="warning-message">{warningMessage}</span>
            }
          </StyledInputAuthorization>
        );
        break;
         case "datePick":
            Ret = (
                <StyledInputAuthorization
                    className={`${className} ${inputAuth1?.font} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}
                    inputAuth={inputAuth1}
                >
                  <DatePicker
                    selected={defaultValue}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChange={date => onChange(date)}
                    name={inputName}
                    placeholderText={placeholder}
                    required={required}
                    disabled={disabled}
                    popperClassName="some-custom-class"
                    popperPlacement="top-end"
                    dateFormat="MM/dd/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    autocomplete='off'
                    popperModifiers={{
                      offset: {
                        enabled: true,
                        offset: "5px, 10px"
                      },
                      preventOverflow: {
                        enabled: true,
                        escapeWithReference: false,
                        boundariesElement: "viewport"
                      }
                    }}
                  />
                    <span className={`placeHold ${inputAuth1?.font}`}>{placeholder}</span>
                    <span className="error-message">{errorMessage}</span>
                    {warningMessage &&
                        <span className="warning-message">{warningMessage}</span>
                    }
                </StyledInputAuthorization>
            );
         break;
        default:
            Ret = (
                <StyledInputAuthorization
                    className={`${className} ${errorMessage ? 'error' : ''} ${warningMessage ? 'warning' : ''}`}
                    inputAuth={inputAuth1}
                >
                    <input className={`t-form-input ${className} ${inputAuth1?.font} ${disabled?"disabled":''}`}
                        {...props}
                        name={inputName}
                        type={type}
                        placeholder={placeholder}
                        required={required}
                        onFocus={focus}
                        disabled={disabled}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        autoComplete="off"
                    />
                <span className="placeHold">{placeholder}</span>
                <span className="error-message">{errorMessage}</span>
                {warningMessage && 
                    <span className="warning-message">{warningMessage}</span>
                }
            </StyledInputAuthorization>
            )
            break;
    }
    return Ret;
});

InputAuthorization.propTypes = {
    type: PropTypes.string.isRequired,
    inputName: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    minDate: PropTypes.object,
    warningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    phone: PropTypes.string,
    setPhone: PropTypes.func,
    onFocus: PropTypes.func,
    className: PropTypes.string,
    onChange: PropTypes.func,
    frontErrorMessage:PropTypes.string,
};
InputAuthorization.defaultProps = {
    required: true
};
export default InputAuthorization;
