import { userService } from '../services';
import { GET_USER_INFO, GET_STORE_INFO, GET_CART_COUNT } from '../config/actionTypes';

export const globals = {
    userInfo,
    storeInfo,
    cartCount
};

function userInfo() {
    return dispatch => {
        userService.GetInfo('/users/info', '')
            .then(
                user => {
                    dispatch(success(user));
                }
            );
    };
    function success(user) { return { type: GET_USER_INFO, user } }
}
function storeInfo() {
    return dispatch => {
        userService.GetInfo('/stores/info', '')
            .then(
                data => {
                    dispatch(success(data));
                }
            );
    };
    function success(storeInfo) { return { type: GET_STORE_INFO, storeInfo } }
}
function cartCount() {
    return dispatch => {
        userService.GetInfo('/shopping_cart/count', '')
            .then(
                data => {
                    localStorage.setItem('itemsCountInShoppingCart', JSON.stringify(data.count));
                    dispatch(success(data.count));
                }
            );
    };
    function success(count) { return { type: GET_CART_COUNT, count } }
}

