import { GET_LOCATION_PAGES, FAILED_LOCATION_PAGES } from '../config/actionTypes';

export function locationPages(state = {}, action) {
    switch (action.type) {
        case GET_LOCATION_PAGES:
            return {
                locationPages: action.locationPages,
            };
        case FAILED_LOCATION_PAGES:
            return {
                error: action.error
            };
        default:
            return state
    };
};

