import styled from "styled-components";

const MobileModal = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform: ${props => props.shown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity: ${props => props.shown ? '1' : '0'};
  z-index: 10;

  .mobile-modal {
    margin-bottom: 80px;
    padding: 16px 16px 32px 32px;
    width: 100%;
    max-width: 386px;
    min-height: 437px;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    z-index: 12;
  }

  .mobile-modal__button {
    margin-bottom: .5rem;
    padding: 8px;
    display: flex;
    margin-left: auto;
    fill: ${({theme}) => theme.colors.white};
    outline: none;

    &:hover,
    &:focus {
      fill: ${({theme}) => theme.colors.brandDark};
    }
  }

  .mobile-modal__close {
    height: 20px;
    width: 20px;
  }

  .mobile-modal__content {
    margin-right: 36px;
  }

  .modal {
    background-image: url(${process.env.PUBLIC_URL + "/images/Gradient.jpeg"});
    background-size: cover;
    background-position-x: -1px;
  }

  .modal__mobile {
    text-transform: uppercase;
    font-weight: 700;
  }

  .modal__mobile--heading {
    font-size: 40px;
    color: ${({theme}) => theme.colors.white};
    padding: 30px 0;
    text-align: center;
  }

  .modal__mobile--text {
    font-size: 18px;
    text-align: left;
    letter-spacing: 0;
    color: ${({theme}) => theme.colors.white};
    line-height: 25px;
    padding: 4px 0 20px;
  }

  .modal__mobile--subHeading {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 19px;
    color: ${({theme}) => theme.colors.white};
    text-align: left;
  }

  .modForm {
    padding-top: 15px;
  }

  .modal__form {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 232px;
  }

  .modal__input {
    height: 40px;
    width: 200px;
  }

  .modal__button {
    display: flex;
    font-size: 18px;
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    background-color: white;
    padding: 12px 15px;
    width: 100%;
    justify-content: space-between;
    span {
      width: 100%;
    }
  }

  .background {
    width: 100vw;
    height: 100vh;
    position: absolute;
    cursor: pointer;
    z-index: 11;
  }
`;

export default MobileModal;