import { userService } from '../services';
import { GET_FAST_LINK, GET_FAST_LINK_LOADING, AEROPAY_USER_BANK_INFO, AEROPAY_USER_BANK_INFO_LOADING } from '../config/actionTypes';

export const aeropayActions = {
  getFastLink,
  postAeroPayAccountAggregate,
};

function getFastLink() {
    return dispatch => {
        dispatch(fastLinkLoading());
        userService.GetInfo('/aeropay/fast-link', '')
            .then(
                fastLink => {
                    dispatch(success(fastLink));
                }
            );
    };
    function fastLinkLoading() { return { type: GET_FAST_LINK_LOADING, loading: true } }
    function success(fastLink) { return { type: GET_FAST_LINK, fastLink } }
};

function postAeroPayAccountAggregate(data) {
    return dispatch => {
        dispatch(loading());
        userService.PostInfo('/aeropay/account-aggregate', data)
            .then(
                bankInfo => {
                    dispatch(success(bankInfo));
                }
            );
    };
    function loading() { return { type: AEROPAY_USER_BANK_INFO_LOADING, loading: true } }
    function success(bankInfo) { return { type: AEROPAY_USER_BANK_INFO, bankInfo } }
}

