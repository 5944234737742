import styled from "styled-components";

const StyledHeaderMainBundle = styled.header`
    --icon-size: 24px;
    font-family: var(--font-DINNextLTPro-MediumCond);
    width: 100%;
    background-color: ${({theme}) => theme.colors.background};
    z-index: 3;
    
    .header-main {
        width: 100%;
        position: relative;
        border-bottom: ${({theme}) => `3px solid ${theme.colors.bundleWhite}`};
    }
    
    .header-main__wrap {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 24px;
        
        @media (max-width: 859px) {
            padding: 6px 10px;
        }
        @media (min-width: 860px) {
            padding: 0 54px 0 8px;
            justify-content: space-between;
        }
    }

    .header-main__logo {
        display: inline-flex;
        margin: 0 auto 0 52px;

        @media (max-width: 859px) {
            width: 90px;
            height: 50px;
            margin: 0 auto 2px 4px;
        }
        @media (min-width: 860px) {
            width: 180px;
            height: 130px;
        }
    }

    .header-main__logo-other {
        display: inline-flex;
        height: auto;

        @media (max-width: 859px) {
            margin-right: 16px;
            max-height: 28px;
        }
        @media (min-width: 860px) {
            max-height: 36px;
        }
    }

    .header-main__home {
        display: inline-flex;
        text-decoration: none;
    }
    
    .title {
        display: flex;
        color: ${({theme}) => theme.colors.bundleWhite};
        font-size: 36px;
        cursor: pointer;
        margin-left: 50px;

        @media (max-width: 859px) {
            display: none;
        }
    }

    .menu_btn__icon {
        margin: 2px 4px;
        width: 12px;
        height: 12px;
        fill: ${({theme}) => theme.colors.brandDark};
        flex-shrink: 0;
        transition: transform 0.5s;

        &.isOpen {
            transform: rotate(-180deg);
        }
    }

    .header-main__icons {
        flex-shrink: 0;
        display: flex;
        font-size: 32px;

        @media (max-width: 859px) {
            margin-left: auto;
            margin-right: 36px;
        }
        @media (min-width: 860px) {
        }
    }

    .header-main__search-ahead {
        @media (max-width: 859px) {
            margin-right: 8px;
            display: inline-flex;
        }
        @media (min-width: 860px) {
            max-width: 423px;
        }
    }
    
    .cart-icon {
        position: relative;
        text-decoration: none;
        color: ${({theme}) => theme.colors.brand};
        .cart-count {
            position: absolute;
            right: -26px;
            top: 10px;
            padding: 6px 4px 4px;
            min-width: 26px;
            height: 26px;
            color: ${({theme}) => theme.colors.white};
            background-color: ${({theme}) => theme.colors.bundleBlue};
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            border-radius: 14px;
            font-size: 20px;
        }
    }

    .header-main__icon-button {
        padding: 10px;
        display: inline-flex;
        fill: ${({theme}) => theme.colors.brandDark};
        transition: var(--button-transition);

        &:hover,
        &:focus {
            fill: ${({theme}) => theme.colors.brand};
        }

        &:not(:first-child) {

            @media (min-width: 400px) and (max-width: 859px) {
                margin-left: 8px;
            }
            @media (min-width: 860px) {
                margin-left: 36px;
            }
        }

        svg {
            width: var(--icon-size);
            height: var(--icon-size);
            fill: inherit;
        }
        &.close-session {
          @media (max-width: 400px) {
            margin-left: 10px;
          }
        }
    }

    .header-main__user {
        @media (max-width: 859px) {
            display: none;
        }
        @media (min-width: 860px) {
            flex-shrink: 0;
            margin-left: 26px;
        }
    }

    .header-main__user__button {
        fill: ${({theme}) => theme.colors.brandDark};

        &:not(:first-of-type) {
            margin-left: 8px;
        }
    }
`;
export default StyledHeaderMainBundle;
