import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom' 
import StyledMobileMenu from "./MobileMenu.styled";
import StyledMobileMenuButton from "./MobileMenuButton.styled";
import SVGIcon from "../utility/SVGIcon";
import MobileMainMenuCard from "../MobileMainMenuCard/MobileMainMenuCard";
import { authActions } from '../../actions/index';
import { FiArrowRight } from "react-icons/fi";

const MobileMenu = React.memo(props => {
  const { featuredBrand, categories, personality, discoversAll, dispatch} = props;
  const [clicked, setClicked] = useState(false);

  // Toggle no-scroll class
  const toggleMenu = () => {
    setClicked (!clicked);
    document.body.classList.remove("no-scroll");
  }
  useEffect(() => {
    clicked && document.body.classList.add("no-scroll");
  })

  // Toggle no-scroll class if route changes to cover edge cases
  const history = useHistory();
  useEffect(() => {
     return history.listen((location) => {
      document.body.classList.remove("no-scroll")
     })
  },[history])

  // Set THC levels
  const thc = [
    {id: "1", name: "Low THC" , uri_param: "lowTHC" },
    {id: "2", name: "Medium THC", uri_param: "mediumTHC" },
    {id: "3", name: "Highest THC", uri_param: "highestTHC" },
  ]

  // Get Login Status for app from local storage
  const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));

  return (
    <>
    <StyledMobileMenuButton onClick={toggleMenu}>
      <SVGIcon name="menu" />
      <span className="t-hidden">open menu</span>
    </StyledMobileMenuButton>

    <StyledMobileMenu data-menu-state={clicked}>
      <nav className="mobile-menu__nav">
        <div className="mobile-menu__nav__header">
          <button className="mobile-menu__close" onClick={toggleMenu}>
            <SVGIcon name="close" />
            <span className="t-hidden">close menu</span>
          </button>
        </div>
        <div className="mobile-menu__nav__body">
          <Link className="mobile-menu__shop-all t-body-medium t-font-artifex" to="/shop" onClick={toggleMenu}>
            SHOP ALL
          </Link>
          {discoversAll && discoversAll.length ?
              <MobileMainMenuCard
                heading="Discover"
                list={discoversAll}
                type="discover"
                toggleMenu={toggleMenu}
              />
            :''}
          {categories && categories.length ?
              <MobileMainMenuCard
                heading="Category"
                list={categories.slice(0, 9)}
                type='category'
                toggleMenu={toggleMenu}
              />
            :''}
          {personality && personality.length ?
              <MobileMainMenuCard
                heading="Personality"
                list={personality}
                type="by_personality"
                toggleMenu={toggleMenu}
              />
            :''}
          {thc && thc.length ?
              <MobileMainMenuCard
                heading="Potency"
                list={thc}
                type="thc"
                toggleMenu={toggleMenu}
              />
            :''}
          {featuredBrand && featuredBrand.length ?
            featuredBrand.map(item =>
            item.brands.length > 0 &&
              <MobileMainMenuCard
                  key={item.id}
                  heading="Featured Brand"
                  list={item.brands}
                  type="brand"
                  toggleMenu={toggleMenu}
              />
              )
          :''}

          <Link className="mobile-menu__shop-all t-body-medium" to="/shop?discover=is_sale&page=1" onClick={toggleMenu}>
            Sale
          </Link>

          <div className="mobile-menu__link-wrap">
            <a className="mobile-menu__shop-all t-body-medium t-font-artifex" href="https://nft.rebud.com" onClick={toggleMenu}>
              NFT
            </a>
            <Link className="mobile-menu__shop-all t-body-medium t-font-artifex" to="/blog" onClick={toggleMenu}>
              LEARN
            </Link>
            <Link className="mobile-menu__shop-all t-body-medium t-font-artifex" to="/promotions" onClick={toggleMenu}>
              PROMO
            </Link>
          </div>

          <div className="mobile-menu__nav__urls">
            { LoginStatus ?
            <>
              <Link onClick={toggleMenu} to={"/rewards"} className="t-list-copy">Rewards</Link>
              <Link onClick={toggleMenu} to={"/account"} className="t-list-copy">My Account</Link>
              <Link onClick={toggleMenu} to={"/account/orders"} className="t-list-copy">My Orders</Link>
              <button className="t-list-copy" onClick={()=> dispatch(authActions.logOut()) }>Log out</button>
            </>
            :
            <>
              <Link onClick={toggleMenu} to={"/rewards"} className="t-list-copy">Rewards</Link>
              <Link onClick={toggleMenu} to="/login"  className="t-list-copy">Log In</Link>
              <Link onClick={toggleMenu} to="/registration"  className="t-list-copy">Register</Link>
            </>
            }
          </div>
        </div>

        <div className="mobile-menu__nav__footer">
          <h3 className="mobile-menu__nav__footer__heading t-copy-heading">Lets Be Buds</h3>
          <ul className="mobile-menu__nav__footer__socials">
            <li className="mobile-menu__nav__footer__social">
              <a href="https://www.instagram.com/rebud.california/" className="t-footer">
                <span className="t-hidden">Instagram</span>
                <SVGIcon name="instagram" />
              </a>
            </li>
            <li className="mobile-menu__nav__footer__social">
              <a href="https://twitter.com/RebudOfficial" className="t-footer">
                <span className="t-hidden">Twitter</span>
                <SVGIcon name="twitter" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      
      <div className="mobile-menu__background" onClick={toggleMenu}/>
    </StyledMobileMenu>
    </>
  )
});

MobileMenu.propTypes = {
  handleOpenLocation: PropTypes.func,
  featuredBrand: PropTypes.array,
  discoversAll: PropTypes.array,
};

export default MobileMenu;