import {CART_LOADING, GET_CART_ITEMS, GET_GIV_AWAY, GET_ADD_ONS} from '../config/actionTypes';

export function getCart(state = {}, action) {
    switch (action.type) {
        case GET_CART_ITEMS:
            return {
                cart: action.cart,
                loading: action.loading
            };
        case CART_LOADING:
            return {
                cart: null,
                loading: action.loading
            };
        default:
            return state
    }
}
export function getGiveAway(state = {}, action) {
    switch (action.type) {
        case GET_GIV_AWAY:
            return {
                giveAway: action.giveAway
            };
        default:
            return state
    }
}
export function getAddOnsCart(state = {}, action) {
    switch (action.type) {
        case GET_ADD_ONS:
            return {
                addOns: action.addOns
            };
        default:
            return state
    }
}