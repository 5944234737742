import styled from "styled-components";

export const StyledSpinnerLoader = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: 10px solid #fff;
  border-top: 8px solid ${props => props.color};
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;

  @keyframes spinner {
    0% {
      transform: rotate(50deg);
    }
    100% {
      transform: rotate(450deg);
    }
  };
`;

export default StyledSpinnerLoader;
