import styled from "styled-components";

const StyledMainMenu = styled.nav`
  padding: 2rem 0 1rem;
  width: 100%;
  display: block;
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0px 9px 15px 0px rgba(0,0,0,0.1);
  z-index: -1;
  transition: top 0.4s ease-in-out;

  @media (max-width: 859px) {
    display: none;
  }

  &[data-menu-state=false] {
    position: absolute;
    top: ${props => props.isKiosk ? '-115vh' : '-108vh'};
    overflow: hidden;
    white-space: nowrap;
    @media(min-width:1024px) {
      margin-top: -150px;
    }
    
  }

  &[data-menu-state=true]{
    top: ${props => props.isKiosk ? '64px' : '118px'};
  }
  
  .main_menu {
    padding-right: 32px;
    padding-left: 32px;
    margin-right: auto;
    margin-left: auto;
    display: grid;
    grid-gap: 20px 40px;
    
    @media (min-width: 860px) and (max-width: 1279px) {
      grid-template-columns: repeat(3, 1fr);
      max-width: 680px;
    }
    @media (min-width: 1280px) {
      max-width: 1300px;
      grid-template-columns: repeat(6, minmax(166px, auto));
    }
  }

  .main-menu__brands {
    grid-column: span 3;
  }

  .main_menu__list-grid {
    display: grid;
    grid-gap: 0 40px;
    grid-template-columns: repeat(3, minmax(166px, 1fr));
  }

  .main_menu__list {
    display: flex;
    flex-direction: column;
    grid-gap: 0 40px;
  }
  
  .main-menu__discover{
    margin-top: 1rem;
  }

  .main_menu__heading {
    margin-bottom: .75rem;
    width: 100%;
    display: block;
    color: ${({ theme }) => theme.colors.brandDark};
  }
  .main-menu-link {
    padding-right: 32px;
    width: fit-content;
    color: ${({ theme }) => theme.colors.brandDark};
    text-decoration: none;
    transition: var(--button-transition);
    display: inline-flex;
    align-items: center;
    position: relative;
    flex-shrink: 0; // Needed for Safari //
    white-space: nowrap; // Needed for Safari //

    svg {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      stroke: ${({ theme }) => theme.colors.brandDark};
      transition: var(--button-transition);
    }

    &:hover,
    &:focus {
      svg {
        right: 0;
      }
    }
  }

  .main-menu-link__brands {
    @media (min-width: 860px) and (max-width: 1279px) {
      grid-area: 3/3;
    }
    @media (min-width: 1280px) {
      grid-area: 2/6;
    }
  }

  .main_menu__lower-wrap {
    margin-top: 2rem;
    border-top: 1px solid ${({ theme }) => theme.colors.gray2};
    width: 100%;
  }

  .main_menu__lower {
    margin-right: auto;
    margin-left: auto;
    padding: 1rem 32px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 860px) and (max-width: 1279px) {
      max-width: 680px;
    }
    @media (min-width: 1280px) {
      max-width: 1300px;
    }
  }

  .main-menu-link__lower {
    border-bottom: 2px solid transparent;
    width: fit-content;
    color: ${({ theme }) => theme.colors.brandDark};
    text-decoration: none;
    transition: var(--button-transition);
    display: inline-block;
    padding-bottom: 1px;
    background-image: linear-gradient(#000, #000);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 3px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
    position: relative;
    flex-shrink: 0; // Needed for Safari //
    white-space: nowrap; // Needed for Safari //

    &:hover,
    &:focus {
      background-position: 100% 100%;
      background-size: 100% 2px;
    }
  }
`;
export default StyledMainMenu;
