import { userService } from '../services';
import { globals } from './global.actions';
import {GET_CART_ITEMS, CART_LOADING, GET_GIV_AWAY, GET_ADD_ONS} from '../config/actionTypes';

export const cartActions = {
    getCart,
    addCartCartPage,
    putCart,
    removeCart,
    getGiveAway,
    getAddOns
};

function getCart(withLoading = false) {
    return dispatch => {
        dispatch(requestLoader());
        if(withLoading) {
          dispatch(cartLoader());
        }
        userService.GetInfo('/shopping_cart','')
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(successLoader());
                },
                error =>{
                    dispatch(failureLoader());
                }
            );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function cartLoader() { return { type: CART_LOADING, loading: true } }
    function successLoader() { return { type: 'SUCCESS' } }
    function failureLoader() { return { type: 'FAILURE' } }
    function success(cart) { return { type: GET_CART_ITEMS, cart, loading: false } }
}
function getGiveAway() {
    return dispatch => {
        dispatch(requestLoader());
        userService.GetInfo('/products/giveaway','')
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(successLoader());
                },
                error =>{
                    dispatch(failureLoader());
                }
            );
    };

    function requestLoader() { return { type: 'REQUEST' } }
    function successLoader() { return { type: 'SUCCESS' } }
    function failureLoader() { return { type: 'FAILURE' } }
    function success(giveAway) { return { type: GET_GIV_AWAY, giveAway } }
}

function getAddOns() {
    return dispatch => {
        dispatch(requestLoader());
        userService.GetInfo('/products/add_ons', '')
            .then(
                data => {
                    dispatch(success(data));
                    dispatch(successLoader());
                },
                error =>{
                    dispatch(failureLoader());
                }
            );
    };

    function requestLoader() { return { type: 'REQUEST' } }
    function successLoader() { return { type: 'SUCCESS' } }
    function failureLoader() { return { type: 'FAILURE' } }
    function success(addOns) { return { type: GET_ADD_ONS, addOns } }
}
function addCartCartPage(data) {
    return dispatch => {
        dispatch(requestLoader());
        return userService.PostInfo('/cartitems', data)
            .then(
                () => {
                    dispatch(cartActions.getCart())
                    dispatch(globals.cartCount())
                },
                error =>{
                  dispatch(cartActions.getCart())
                  dispatch(globals.cartCount())
                    dispatch(failureLoader());
                }
            );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function failureLoader() { return { type: 'FAILURE' } }
}
function putCart(data) {
    return dispatch => {
        dispatch(requestLoader());
        userService.PutInfo(`/cartitems/${data.productId}`, { quantity: data.quantity})
            .then(
                () => {
                    dispatch(cartActions.getCart());
                    dispatch(globals.cartCount())
                },
                error =>{
                    dispatch(failureLoader());
                }
            );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function failureLoader() { return { type: 'FAILURE' } }
}
function removeCart(data) {
    return dispatch => {
        dispatch(requestLoader());
        return userService.DeleteInfo(`/cartitems/${data.productId}`, '')
            .then(
                () => {
                    dispatch(cartActions.getCart());
                    dispatch(globals.cartCount())
                    dispatch(cartActions.getGiveAway())
                },
                () =>{
                    dispatch(failureLoader());
                }
            );
    };
    function requestLoader() { return { type: 'REQUEST' } }
    function failureLoader() { return { type: 'FAILURE' } }
}