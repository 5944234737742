import everscoreTheme from "../../styles/themes/EverscoreTheme";
import rebudTheme from "../../styles/themes/DefaultTheme";
import bundleTheme from "../../styles/themes/BundleTheme";

const configEverScore = {
    contactUsConfig: {
        background:'',
        colorFonts:'',
        classMainFont: '',
        classNameTitleFont: '',
        classNameDescription: '',
        color: 'brandDark',
        pstText: 'PST',
        mainPageHeadClass1: 't-section-title',
        mainPageHeadClass2: 't-link',
        mainPageHeadClass3: '',
        mainPageHeadClass4: '',
        contactFormFont: '',
        contactFormClass: 't-section-heading',
        buttonFont: '',
        buttonColor: "",
    },
    checkoutConfig: {
        headingClass: 't-section-heading',
        classNameFont: '',
        fontName1:  '',
        iconName1:  'checked',
        iconName2:  'check',
        steps: {
            font1: '',
            font2: 't-font-NHaasGroteskDSPro-65Md',
            font3: '',
            font4: 't-font-NHaasGroteskDSPro-55Rg',
            font5: 't-font-NHaasGroteskDSPro-55Rg',
            font6: 't-font-NHaasGroteskDSPro-55Rg',
            font7: '',
            color1: everscoreTheme.colors.gray2,
            color2: '',
            color3: everscoreTheme.colors.brandDark,
            color4: everscoreTheme.colors.gray3,
            classNameFont1: '',
        },
        simpleModal: {
            color1: everscoreTheme.colors.white,
            color2: ''
        },
        mainFont1: 't-title-small',
        mainFont2: 't-font-NHaasGroteskDSPro-55Rg',
        mainFont3: '',
        mainFont4: 't-font-NHaasGroteskDSPro-55Rg',
        iconName3: 'delivery-circle-active-everscore',
        mainFont5: 't-font-NHaasGroteskDSPro-55Rg',
        iconName4: 'delivery-normal-box-everscore',
        mainFont6: '',
        dark: true,
        mainFont7: '',
        mainFont8: '',
        mainFont9: 't-font-lato-regular',
        mainText1: 'Everscore',
        inputAuth1: {
            font: '',
            color1: '#f5f5f5',
            color2: everscoreTheme.colors.gray3,
            color3: everscoreTheme.colors.gray2,
            color4: everscoreTheme.colors.white,
            color5: everscoreTheme.colors.brandDark,
            color6: everscoreTheme.colors.gray1,
            color7: everscoreTheme.colors.gray3,
            color8: everscoreTheme.colors.brandDark,
            font1: '',
            error: everscoreTheme.colors.error,
            color9: rebudTheme.colors.gray3,
            color10: "#fff",
            color11: everscoreTheme.colors.gray3,
        },
        dropUpload: {
            color1: '',
            color2: everscoreTheme.colors.gray1,
            color3: '',
            color4: everscoreTheme.colors.brand,
            iconName1: 'img-upload-everscore',
            error: everscoreTheme.colors.error,
            font: "",
            errorText: rebudTheme.colors.gray4,
        },
        themeLocationSearchInput: {
            font1: 't-form-input',
            font2: '',
            font3: '',
            zipColor1: everscoreTheme.colors.white,
            zipColor2: everscoreTheme.colors.gray3,
            padding: '0',
            height1: '36px',
            height2: '26px',
            borderRadius: '0',
            border: `none`,
            borderBottom: `solid 1px ${rebudTheme.colors.flowerBrandDark}`,
            font4: '',
            color1: '#ffffff',
            color2: ''
        },
        iconName5: 'store-pickup-everscore',
        checkoutStyles: {
            color1: '',
            font1: 'NHaasGroteskDSPro-55Rg, sans-serif',
            color2: '',
            color3: everscoreTheme.colors.gray4,
            color4: everscoreTheme.colors.brandDark,
            color5: everscoreTheme.colors.white,
            color6: everscoreTheme.colors.brand,
            color7: everscoreTheme.colors.white,
            color8: '',
            color9: '',
            color10: '',
            color11: everscoreTheme.colors.gray3,
            color12: everscoreTheme.colors.gray3,
            color13: everscoreTheme.colors.everscoreBrandBright,
            color14: everscoreTheme.colors.gray1,
            color15: everscoreTheme.colors.everscoreBrandBright,
            color16: everscoreTheme.colors.white,
            color17: everscoreTheme.colors.gray4,
            color18: everscoreTheme.colors.everscoreBrandBright,
            color19: '',
            ontSize1: '',
            color20: everscoreTheme.colors.gray3,
            color21: everscoreTheme.colors.brandDark,
            color22: '',
            color23: everscoreTheme.colors.brandDark,
            color24: everscoreTheme.colors.brand,
            color25: '',
            color26: everscoreTheme.colors.gray3,
            color27: everscoreTheme.colors.gray4,
            color28: '',
            color29: everscoreTheme.colors.gray1,
            color30: everscoreTheme.colors.gray2,
            color31: everscoreTheme.colors.black,
            color32: everscoreTheme.colors.white,
            margin: '0 auto',
            overflow: 'initial',
            color33: '#757575',
            color34: everscoreTheme.colors.everscoreBrandBright,
            error: everscoreTheme.colors.warning,
            maxWidth: 'initial',
            font2: '',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: ''
        },
        summaryConfig: {
            font1: '',
            font2: '',
            font3: '',
            font4: '',
        },
        priceItemColor: '#222'
    }
}
export default configEverScore