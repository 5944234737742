import React from "react";
import HeaderDeliveryInput from "../HeaderDeliveryInput/HeaderDeliveryInput";
import StyledHeaderMainSecondary from "./HeaderMainSecondary.styled";

const HeaderMainSecondary = React.memo(() => {
  return (
    <StyledHeaderMainSecondary>
      <div className="header-main__wrap">
        <HeaderDeliveryInput />
      </div>
    </StyledHeaderMainSecondary>
  );
});

export default HeaderMainSecondary;
