import { userService } from '../services';
import { history } from '../helpers';
import { globals } from './global.actions';
import {
  ADD_CART_LOADING,
  ADD_CART,
  ADD_CART_FAIL,
  GET_SIMILAR_PRODUCTS,
  GET_PRODUCT_DETAILS,
  CHANGE_PRODUCT_DETAILS, FAILED_PRODUCT_DETAILS
} from "../config/actionTypes";
import qs from "qs";


export const productActions = {
    addCard,
    buyNow,
    productDetails,
    changeProductDetails,
    getSimilarProducts
};

function addCard(data, klaviyoData) {
    return dispatch => {
        dispatch(cartLoader());
        userService.PostInfo('/cartitems', data)
            .then(
                (data) => {
                  dispatch(success(data));
                  dispatch(globals.cartCount())
                  const _learnq =  window._learnq || [];
                  const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));
                  if(klaviyoData && LoginStatus){
                      _learnq.push(["track", "Added to Cart",  klaviyoData])
                  }
                }
            ).catch(
              () => {
                dispatch(fail());
              }
        );
    };
  function cartLoader() { return { type: ADD_CART_LOADING, loading: true } }
  function success(addCart) { return { type: ADD_CART, addCart, loading: false } }
  function fail() { return { type: ADD_CART_FAIL, loading: false } }
}
function buyNow(data) {
    return dispatch => {
        userService.PostInfo('/cartitems', data)
            .then(
                () => {
                    history.push('/cart')
                    dispatch(globals.cartCount())
                }
            );
    };
}

function getSimilarProducts(queryParams, urlParams) {
  return dispatch => {
    let endpoint = '/products/similar/category/:productId';
    if (urlParams) {
      Object.keys(urlParams).forEach(k => {
        endpoint = endpoint.replace(':' + k, urlParams[k]);
      });
    }
    if (queryParams) {
      endpoint += '?' + qs.stringify(queryParams)
    }
    userService.GetInfo(endpoint, "")
      .then(
        data => {
          dispatch(success(data));
        }
      );
  };
  function success(data) { return { type: GET_SIMILAR_PRODUCTS, data }}
}

function productDetails(id, childId) {
  return dispatch => {
    dispatch(requestLoader());
    userService.GetInfo('/products/', id)
      .then(
        product => {
          dispatch(success(product, childId));
          dispatch(successLoader());
        },
        (err) =>{
          dispatch(failureLoader());
          dispatch(failed(err));
        }
      );
  };
  function requestLoader() { return { type: 'REQUEST' } }
  function successLoader() { return { type: 'SUCCESS' } }
  function failureLoader() { return { type: 'FAILURE' } }
  function failed(error) { return { type: FAILED_PRODUCT_DETAILS, error }}
  function success(product, childId) { return { type: GET_PRODUCT_DETAILS, data:{product: product, childId: childId } }}
}

function changeProductDetails(data) {
  return dispatch => {
    dispatch(success(data));
  };
  function success(changeProduct) { return { type: CHANGE_PRODUCT_DETAILS, changeProduct } }
}
