import React, { PureComponent } from "react";
import HeroPromoAnimated from "../HeroPromoAnimated/HeroPromoAnimated";
import HeroPromoAnimatedFlower from "../HeroPromoAnimated/HeroPromoAnimatedFlower";
import {selectedTheme} from "../../config/constants";
import PropTypes from "prop-types";

class HeroPromoAnimatedContainer extends PureComponent {
    render() {
        const {promoBar} = this.props;
        switch(selectedTheme) {
            case 'FlowerTheme':
                return <HeroPromoAnimatedFlower promoBar={promoBar} />;
            default:
                return <HeroPromoAnimated promoBar={promoBar} />;
        }
    }
}

HeroPromoAnimatedContainer.propTypes = {
    promoBar: PropTypes.array.isRequired
};


export default HeroPromoAnimatedContainer;
