import styled from "styled-components";

const StyledPlacesContainerBundle = styled.div`
    margin-top: 1rem;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.colors.bundleWhite};
    color: ${({ theme }) => theme.colors.bundleBlack};
    border: solid 1px ${({ theme }) => theme.colors.gray2};
    padding: 0.5rem;
    position: absolute;
    z-index: 999;
    width: 100%;
    border-radius: 0 0 3px 3px;
    
    &.active {
        display: flex;
    }
    
    .location-wrap {
        position: relative;
    }
    
    .suggestion-item {
        padding: 0.3rem;
        text-align: left;
        font-weight: var(--weight-regular);
        &--active {
            text-align: left;
            cursor: pointer;
            background-color: aliceblue;
            padding: 0.3rem;
        }
    }
`;

export default StyledPlacesContainerBundle;