import React from "react";
import styled from "styled-components";

const StyledInputZipBundle = styled.input`
  padding: 1.375rem 16px;
  width: 100%;
  height: 64px;
  border-radius: 4px;
  border: solid 1px ${({ theme }) => theme.colors.gray2};
  background-color: ${({ theme }) => theme.colors.bundleWhite};
  transition: var(--button-transition);
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.bundleGrey2};
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.brand}
  }
  &.disabled {
    border: solid 1px ${({ theme }) => theme.colors.gray1};
    background-color: ${({ theme }) => theme.colors.gray1};
  }
`;

export default React.memo(props => <StyledInputZipBundle {...props} />);