import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";

import { Link } from "react-router-dom";
import { storeType, storeName } from "../../config/constants";
import { useLocation } from "react-router-dom";
import StyledHeaderSimpleBundle from "./HeaderSimpleBundle.styled";
import SVGIcon from "../../components/utility/SVGIcon";
import { storeInfoPT }  from "../../config/prop-types";

const HeaderSimpleBundle = React.memo(props => {
    const { className, storeInfo } = props;

    // Look up current route
    const currentRoute = useLocation();
    const formatPathName = (str) => {
        let lettersOnly = ('' + str).replace(/[/]/g, ' ');
        let formatPath = lettersOnly.charAt(1).toUpperCase() + lettersOnly.slice(2);

        return formatPath;
    };

    return (
        <StyledHeaderSimpleBundle className={className}>
            <h1 className="t-hidden">
                {storeName}
                {currentRoute.pathname === "/" ?
                    ""
                    :
                    `- ${formatPathName(currentRoute.pathname)}`
                }
            </h1>
            <Link to="/" className="header-simple__link">
                {storeType === 'rebud' ?
                    <SVGIcon name="bundle-icon" className="bundle-logo" />
                    :
                    <img src={storeInfo && storeInfo.watermark_logo} alt={storeInfo && storeInfo.name} className="header-simple__logo-other" />
                }
                <span className="t-hidden">{storeName} home</span>
            </Link>
        </StyledHeaderSimpleBundle>
    );
});

const mapStateToProps = ({ storeInfo }) => ({
    storeInfo: storeInfo.storeInfo
});

HeaderSimpleBundle.propTypes = {
    className: PropTypes.string,
    storeInfo: storeInfoPT,
};

export default connect(mapStateToProps)(HeaderSimpleBundle);
