import React, { useState, useEffect } from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom' 
import StyledMobileMenu from "./MobileMenuFlower.styled";
import StyledMobileMenuButton from "./MobileMenuFlowerButton.styled";
import SVGIcon from "../../utility/SVGIcon";
import MobileMainMenuFlowerCard from "../../MobileMainMenuCardFlower/MobileMainMenuFlowerCard";
import { authActions } from '../../../actions/index';

const MobileMenuFlower = React.memo(props => {
  const { featuredBrand, categories, dispatch} = props;
  const [clicked, setClicked] = useState(false);

  // Toggle no-scroll class
  const toggleMenu = () => {
    setClicked (!clicked);
    document.body.classList.remove("no-scroll");
  }
  useEffect(() => {
    clicked && document.body.classList.add("no-scroll");
  })

  // Toggle no-scroll class if route changes to cover edge cases
  const history = useHistory();
  useEffect(() => {
     return history.listen((location) => {
      document.body.classList.remove("no-scroll")
     })
  },[history])

  // Set THC levels
  const thc = [
    {id: "3", name: "Hits Hard", uri_param: "highestTHC" },
    {id: "2", name: "Regular", uri_param: "mediumTHC" },
    {id: "1", name: "Low Dose" , uri_param: "lowTHC" },
  ]

  // Get Login Status for app from local storage
  const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));

  return (
    <>
    <StyledMobileMenuButton onClick={toggleMenu}>
      <SVGIcon name="menu" />
      <span className="t-hidden">open menu</span>
    </StyledMobileMenuButton>

    <StyledMobileMenu data-menu-state={clicked}>
      <nav className="mobile-menu__nav">
        <div className="mobile-menu__nav__header">
          <button className="mobile-menu__close" onClick={toggleMenu}>
            <SVGIcon name="close" />
            <span className="t-hidden">close menu</span>
          </button>
        </div>
        <div className="mobile-menu__nav__body">
          {categories && categories.length ?
              <MobileMainMenuFlowerCard
                heading="Shop By Form"
                list={categories.slice(0, 9)}
                type='category'
                toggleMenu={toggleMenu}
              />
            :''}
          {thc && thc.length ?
              <MobileMainMenuFlowerCard
                heading="Shop By Potency"
                list={thc}
                type="thc"
                toggleMenu={toggleMenu}
              />
            :''}
          {featuredBrand && featuredBrand.length ?
            featuredBrand.map(item =>
            item.brands.length > 0 &&
              <MobileMainMenuFlowerCard
                  key={item.id}
                  heading="Featured Brands"
                  list={item.brands}
                  type="brand"
                  toggleMenu={toggleMenu}
              />
              )
          :''}

          <Link className="mobile-menu__shop-all t-shelf-heading-flower" to="/shop" onClick={toggleMenu}>
            SHOP ALL
            <SVGIcon
                className="up-arrow-icon"
                name="up-arrow-icon-flower"
            />
          </Link>

          <div className="mobile-menu__nav__urls">
            { LoginStatus ?
            <>
              <Link onClick={toggleMenu} to={"/account"} className="t-list-copy t-font-Moderat-Mono-Regular">My Account</Link>
              <Link onClick={toggleMenu} to={"/"} className="t-list-copy t-font-Moderat-Mono-Regular">Location</Link>
              <button className="t-list-copy t-font-Moderat-Mono-Regular" onClick={()=> dispatch(authActions.logOut()) }>Log out</button>
            </>
            :
            <>
              <Link onClick={toggleMenu} to="/login"  className="t-title-small t-font-Moderat-Mono-Regular">Log In</Link>
              <Link onClick={toggleMenu} to="/registration"  className="t-title-small t-font-Moderat-Mono-Regular">Register</Link>
            </>
            }
          </div>
        </div>

        {/*<div className="mobile-menu__nav__footer">*/}
        {/*  <ul className="mobile-menu__nav__footer__socials">*/}
        {/*    <li className="mobile-menu__nav__footer__social">*/}
        {/*      <a href="https://twitter.com/RebudOfficial" className="t-footer">*/}
        {/*        <span className="t-hidden">Twitter</span>*/}
        {/*        <SVGIcon name="twitter" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*    <li className="mobile-menu__nav__footer__social">*/}
        {/*      <a href="https://www.instagram.com/rebudofficial" className="t-footer">*/}
        {/*        <span className="t-hidden">Instagram</span>*/}
        {/*        <SVGIcon name="instagram" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}
      </nav>
      
      <div className="mobile-menu__background" onClick={toggleMenu}/>
    </StyledMobileMenu>
    </>
  )
});

MobileMenuFlower.propTypes = {
  handleOpenLocation: PropTypes.func,
  featuredBrand: PropTypes.array,
};

export default MobileMenuFlower;
