import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledMainMenuCard = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  font-weight: normal;
  @media (max-width: 459px) {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  @media (min-width: 460px) {
    padding-top: .3rem;
    padding-bottom: .3rem;
  }
  &:hover,
  &:focus {
    .main_menu-card__heading {
      background-position: 100% 100%;
      background-size: 100% .5px;
    }
  }

  .main_menu-card__heading {
    color: ${({theme}) => theme.colors.brandDark};
    transition: var(--button-transition);
    display: inline-block;
    padding-bottom: 1px;
    background-image: linear-gradient(#000, #000);
    background-position: 0 100%; /*OR bottom left*/
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
      background-size 0.3s,
      background-position 0s 0.3s;
  }
`;

export default StyledMainMenuCard;