import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import StyledProfileDropdownEverscore from "./ProfileDropdownEverscore.styled";
import { Link } from "react-router-dom";
import { authActions } from '../../../actions/index';
import SVGIcon from "../../utility/SVGIcon";

const ProfileDropdownEverscore = React.memo(props => {
  const { className, dispatch } = props;
  const [ clicked, setClicked ] = useState(false);
  const node = useRef();

  const toggleDropdown = () => setClicked (!clicked);

  const handleClick = e => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setClicked(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  // Get Login Status for app from local storage
  const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));

  return (
    <StyledProfileDropdownEverscore aria-haspopup="true" aria-expanded={clicked} className={className} ref={node}>
      <button className="profile-dropdown__media" onClick={toggleDropdown}>
        <SVGIcon name="everscore-user-icon" />
        <span className="t-hidden">user profile options</span>
      </button>

      <ul className="profile-dropdown__options" role="menu" data-profile-dropdown-state={clicked} onClick={toggleDropdown}>
        { LoginStatus ?
          <>
          <li className="profile-dropdown__option">
            <Link to="/account" role="menuitem" className="t-form-button profile-dropdown__option__link t-shelf-link-mono-everscore">Account</Link>
          </li>
          <li className="profile-dropdown__option">
            <Link to="/account/orders" role="menuitem" className="t-form-button profile-dropdown__option__link t-shelf-link-mono-everscore">Orders</Link>
          </li>
          <li className="profile-dropdown__option">
            <button role="menuitem"
                    className="t-copyright-everscore profile-dropdown__option__sign-out"
                    onClick={()=> dispatch(authActions.logOut()) }
            >Log out</button>
          </li>
          </>
          :
          <>
          <li className="profile-dropdown__option">
            <Link to="/login" role="menuitem" className="t-form-button profile-dropdown__option__link t-shelf-link-mono-everscore">Log In</Link>
          </li>
          <li className="profile-dropdown__option">
            <Link to="/registration" role="menuitem" className="t-form-button profile-dropdown__option__link t-shelf-link-mono-everscore">Register</Link>
          </li>
          </>
        }
      </ul>
    </StyledProfileDropdownEverscore>
  );
});

ProfileDropdownEverscore.propTypes = {
  className: PropTypes.string
};

export default ProfileDropdownEverscore;
