// eslint-disable-next-line no-unused-vars
import React from "react";
import { userService } from '../services';
import {isKiosk, storeName} from "../config/constants";
import {
    POST_PROMO,
    CHECK_SUMMARY,
    CHECK_SUMMARY_REQUEST,
    CHECK_SUMMARY_FAIL,
    POST_PROMO_LOADING,
    POST_PROMO_FAIL,
    EMPTY_FAST_LINK,
    EMPTY_AEROPAY_USER_BANK_INFO,
    ORDER_FAILED,
    ORDER_SUCCESS,
    ORDER_REQUEST,
} from '../config/actionTypes';
import qs from "qs";
import * as toast from "../components/utility/toast";
import {history} from "../helpers";
import {authActions} from "./auth.actions";
import cookie from "react-cookies";
import {promoActions} from "./promo.actions";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import _ from "lodash";

export const checkout = {
    postOrder,
    postPromo,
    checkSummary,
    sendEmailLink,
    sendSmsCode,
    postOrderPaytender,
    paytenderOrderStatus,
    debitOrderStatus,
    changeOrderPaytender,
    postOrderDebit,
    postOrderAeroPay,
    paymentsOrderStatus
};
let cookieFirebase = cookie.load('HDeviceToken');

function postOrder(data) {
    let endpoint = '/orders';
    if( cookieFirebase ){
        data.firebase_token = cookieFirebase
    }
    return dispatch => {
      dispatch(requestLoader())
      userService.PostInfo(endpoint, data)
        .then(
          data => {
              dispatch(success())
              if(isKiosk){
                  dispatch(authActions.logOutKiosk());
              }
              else {
                dispatch(promoActions.getWelcomePromo())
                if (!data.id) {
                    history.push('/account/orders')
                } else {
                    history.push(`/account/orders/${data.id}?status=success`)
                }
              }
        }
      )
       .catch( () => {
          dispatch(successLoader());
          dispatch(requestFail)
        }
      );
    }
    function success() { return { type: ORDER_SUCCESS, loading: false } }
    function requestFail(err) { return { type: ORDER_FAILED, loading: false } }
    function requestLoader() { return { type: ORDER_REQUEST, loading: true } }
    function successLoader() { return { type: 'SUCCESS' } }
}

function postOrderPaytender(data) {
    let endpoint = '/pay-tender';
    if( cookieFirebase ){
        data.firebase_token = cookieFirebase
    }
    return dispatch => {
        dispatch(requestLoader())
        userService.PostInfo(endpoint, data)
          .then(
            data => {
                window.location.href = data.redirectUrl
            }
        )
         .catch( () => {
            dispatch(successLoader());
          }
        );
      }
      function requestLoader() { return { type: 'REQUEST_CHECKOUT' } }
      function successLoader() { return { type: 'SUCCESS_CHECKOUT' } }
}

function postOrderAeroPay(data) {
    let endpoint = '/aeropay';
    const {company_name} = data
    if( cookieFirebase ){
        data.firebase_token = cookieFirebase
    }
    return dispatch => {
        dispatch(requestLoader())
        userService.PostInfo(endpoint, data)
            .then(
                orderData => {
                    if(isKiosk){
                        dispatch(authActions.logOutKiosk());
                    }
                    else {
                        if (!orderData.id) {
                            history.push('/account/orders')
                        } else {
                            history.push(`/account/orders/${orderData.id}?status=success`)
                        }
                    }
                    dispatch(checkout.paymentsOrderStatus()).then(data => {
                        const tagManagerArgs = {
                            dataLayer: {
                                event: 'OrderSuccess',
                                orderID: data.order_id,
                                orderTotal: data.order.total_cost
                            }
                        }
                        TagManager.dataLayer(tagManagerArgs)
                        if (data.order.orderItems) {
                            for (let i = 0; i < data.order.orderItems.length; i++) {
                                ReactGA.plugin.execute('ec', 'addProduct', {
                                    id: data.order.orderItems[i].id,
                                    name: data.order.orderItems[i].product.name,
                                    brand: data.order.orderItems[i].product.brand,
                                    category: data.order.orderItems[i].product.categories,
                                    variant: data.order.orderItems[i].product.product_type_name,
                                    sku: data.order.orderItems[i].product_id,
                                    price: data.order.orderItems[i].cost,
                                    quantity: data.order.orderItems[i].quantity,
                                });
                            }
                            ReactGA.ga('set', 'currencyCode', 'USD');
                            ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
                                id: data.order_id,
                                affiliation: storeName,
                                revenue: Number(data.order.total_cost),
                                shipping: Number(data.order.delivery_cost),
                                tax: Number(data.order.city_tax) + Number(data.order.excise_tax) + Number(data.order.non_medical_fee) + Number(data.order.sales_tax) + Number(data.order.tip)
                            });
                            ReactGA.pageview('/orderDetails');
                            ReactGA.plugin.execute('ec', 'clear');
                        }
                        //klaviyo data
                        const _learnq =  window._learnq || [];
                        const brand = _.flatMap(data.order.orderItems, item => ([item.product.brand]));
                        const name = _.flatMap(data.order.orderItems, item => ([item.product.name]));
                        const categories = _.flatMap(data.order.orderItems, item => ([item.product.categories]));
                        const product_type_name = _.flatMap(data.order.orderItems, item => ([item.product.product_type_name]));
                        _learnq.push(["track", "Purchase done", {...data.order, location: company_name, brand, name, categories, product_type_name, order_id: data.order_id }]);
                    })
                    dispatch(successLoader());
                    dispatch(emptyAeroPayFastLink());
                    dispatch(emptyAeroPayBankData());
                }
            )
            .catch( () => {
                    dispatch(successLoader());
                }
            );
    }
    function requestLoader() { return { type: 'REQUEST_CHECKOUT' } }
    function successLoader() { return { type: 'SUCCESS_CHECKOUT' } }
    function emptyAeroPayFastLink() { return { type: EMPTY_FAST_LINK } }
    function emptyAeroPayBankData() { return { type: EMPTY_AEROPAY_USER_BANK_INFO } }
}

function postOrderDebit(data) {
    let endpoint = '/orders';
    if( cookieFirebase ){
        data.firebase_token = cookieFirebase
    }
    return dispatch => {
        dispatch(requestLoader())
        userService.PostInfo(endpoint, data)
            .then(
                data => {
                    history.push('/debit-processing')
                    dispatch(successLoader());
                }
            )
            .catch( () => {
                    dispatch(successLoader());
                }
            );
    }
    function requestLoader() { return { type: 'REQUEST_CHECKOUT' } }
    function successLoader() { return { type: 'SUCCESS_CHECKOUT' } }
}

function changeOrderPaytender(id) {
    let endpoint = `/pay-tender/change/${id}`;
    return dispatch => {
        userService.GetInfo(endpoint, '')
            .then(
                data => {
                    window.location.href = data.redirectUrl
                }
            )

    }
}

function sendEmailLink(data) {
    return () => {
        userService.PostInfo('/auth/send-email', data)
          .then(
            (data) => toast.success({ text: data.message })
          );
    };
}

function paytenderOrderStatus() {
    return () => {
       return userService.GetInfo('/pay-tender/status', '')
    };
}

function debitOrderStatus() {
    return () => {
       return userService.GetInfo('/orders/debit-status', '')
    };
}

function paymentsOrderStatus() {
    return () => {
        return userService.GetInfo('/orders/payment-status', '');
    };
}


function sendSmsCode(data) {
    return () => {
        userService.PostInfo('/auth/resend-otp', data)
          .then(
            (data) => {
                toast.success({text: data.message})
                window.open(`${window.location.origin}/phone-verification`);
            }
          );
    };
}

function postPromo(urlParams) {
    let endpoint = "/promo_codes/validate/:code";

    if (urlParams) {
        Object.keys(urlParams).forEach(k => {
            endpoint = endpoint.replace(':' + k, urlParams[k]);
        });
    }
    if( cookieFirebase ){
        endpoint += `?firebase_token=${cookieFirebase}`
        urlParams.firebase_token = cookieFirebase
    }

    return dispatch => {
      dispatch(loading())
      userService.GetInfo(endpoint, '')
        .then(
          data => {
            toast.success({text: data.message})
            dispatch(success(data))
          }
        ).catch(
            error => {
                dispatch(requestFail(error))
                if (error.status === 400) {
                    toast.error({ text: error.message })
                }
            }
        )
    }
    function success(promo) { return { type: POST_PROMO, promo, loading: false } }
    function loading() { return { type: POST_PROMO_LOADING, promo: null, loading: true } }
    function requestFail(err) { return { type: POST_PROMO_FAIL, promo: null, loading: false } }
}


function checkSummary(data, queryParams, urlParams) {
    let endpoint = '/orders/costs/delivery';
    if( cookieFirebase ){
        queryParams.firebase_token = cookieFirebase
    }
    if (urlParams) {
        Object.keys(urlParams).forEach(k => {
            endpoint = endpoint.replace(':' + k, urlParams[k]);
        });
    }
    if (queryParams) {
        endpoint += '?' + qs.stringify(queryParams)
    }
    return dispatch => {
      dispatch(requestLoader())
      return userService.GetInfo(endpoint, '')
        .then(
          data => {
            dispatch(success(data))
          }
        ).catch(
          err => {
            dispatch(requestFail(err))
          }
      )
    };
    function requestLoader() { return { type: CHECK_SUMMARY_REQUEST } }
    function requestFail(err) { return { type: CHECK_SUMMARY_FAIL } }
    function success(summary) { return { type: CHECK_SUMMARY, summary } }
}
