import React from "react";
import HeaderDeliveryInputFlower from "../HeaderDeliveryInputFlower/HeaderDeliveryInputFlower";
import StyledHeaderMainSecondaryFlower from "./HeaderMainSecondaryFlower.styled";

const HeaderMainSecondaryFlower = React.memo(() => {
  return (
    <StyledHeaderMainSecondaryFlower>
      <div className="header-main__wrap">
        <HeaderDeliveryInputFlower />
      </div>
    </StyledHeaderMainSecondaryFlower>
  );
});

export default HeaderMainSecondaryFlower;
