import styled from "styled-components";

const StyledHeaderMainSecondary = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({theme}) => theme.colors.gray2};

  .header-main__wrap {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    @media (max-width: 859px) {
      padding: 1rem 8px;
      justify-content: center;
    }
    @media (min-width: 860px) {
      padding: 6px 32px;
      min-height: 54px;
    }
  }

  .autocomplete-dropdown-container {
    top: 2.375rem;
  }
`;

export default StyledHeaderMainSecondary;
