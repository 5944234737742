import React, { lazy } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import LazyLoad from "./LazyLoad";
import cookie from "react-cookies";
import { storeDomain } from "./constants";

/**
|--------------------------------------------------
| Route Config
|--------------------------------------------------
*/

const HomeBundle = lazy(() => import("../containers/HomeBundle/HomeContainerBundle"));
const LazyHomeBundle = (props) => <LazyLoad component={HomeBundle} {...props} />;

const Login = lazy(() => import("../containers/LoginBundle/LoginContainerBundle"));
const LazyLogin = (props) => <LazyLoad component={Login} {...props}/>;

const Registration = lazy(() => import("../containers/RegistrationBundle/RegistrationContainerBundle"));
const LazyRegistration = (props) => <LazyLoad component={Registration} {...props}/>;

const PhoneVerification = lazy(() =>
  import("../containers/PhoneVerificationBundle/PhoneVerificationContainerBundle")
);
const LazyPhoneVerification = (props) => (
  <LazyLoad component={PhoneVerification} {...props}/>
);

const ResetPassword = lazy(() =>
  import("../containers/ResetPasswordBundle/ResetPasswordBundleContainer")
);
const LazyResetPassword = (props) => (
  <LazyLoad component={ResetPassword} {...props} />
);

const ForgotPassword = lazy(() =>
  import("../containers/ForgotPasswordBundle/ForgotPasswordContainerBundle")
);
const LazyForgotPassword = (props) => (
  <LazyLoad component={ForgotPassword} {...props}/>
);

const ForgotVerification = lazy(() =>
  import("../containers/ForgotVerificationBundle/ForgotVerificationBundleContainer")
);
const LazyForgotVerification = (props) => (
  <LazyLoad component={ForgotVerification} {...props} />
);

const Account = lazy(() => import("../containers/AccountBundle/AccountContainerBundle"));
const LazyAccount = (props) => <LazyLoad component={Account} {...props}/>;

const AccountOrders = lazy(() =>
  import("../containers/AccountBundle/AccountOrdersContainerBundle")
);
const LazyAccountOrders = (props) => (
  <LazyLoad component={AccountOrders} {...props}/>
);

const AccountOrderDetails = lazy(() =>
  import("../containers/AccountBundle/AccountOrderDetailsContainerBundle")
);
const LazyAccountOrderDetails = (props) => (
  <LazyLoad component={AccountOrderDetails} {...props}/>
);

const AccountOrderCanceling = lazy(() =>
  import("../containers/AccountBundle/AccountOrderCancelingContainerBundle")
);
const LazyAccountOrderCanceling = (props) => (
  <LazyLoad component={AccountOrderCanceling} {...props}/>
);

const ContactUsBundle = lazy(() =>
    import("../containers/ContactUs/ContactUsContainer")
);
const LazyContactUsBundle = (props) => <LazyLoad component={ContactUsBundle} {...props} />;

const BundlePage = lazy(() =>
  import("../containers/BundlePage/BundlePageContainer")
);
const LazyBundlePage = (props) => (
  <LazyLoad component={BundlePage} {...props}/>
);

const Cart = lazy(() => import("../containers/CartBundle/CartContainerBundle"));
const LazyCart = (props) => <LazyLoad component={Cart} {...props}/>;

const ShopBundle = lazy(() => import("../containers/ShopBundle/ShopBundle"));
const LazyShopBundle = (props) => <LazyLoad component={ShopBundle} {...props}/>;

const Checkout = lazy(() => import("../containers/Checkout/CheckoutContainer"));
const LazyCheckout = (props) => <LazyLoad component={Checkout} {...props}/>;

const Success = lazy(() => import("../containers/Success/SuccessContainer"));
const LazySuccess = (props) => <LazyLoad component={Success} {...props} />;

const EmailVerification = lazy(() =>
  import("../containers/EmailVerificationBundle/EmailVerificationContainerBundle")
);
const LazyEmailVerification = (props) => (
  <LazyLoad component={EmailVerification} {...props} />
);

const TermsConditionsBundle = lazy(() => import("../containers/TermsConditionsBundle/TermsConditionsContainerBundle"));
const LazyTermsConditionsBundle = (props) => <LazyLoad component={TermsConditionsBundle} {...props} />;

const Partnerships = lazy(() => import("../containers/Partnerships/Partnerships"));
const LazyPartnerships = (props) => <LazyLoad component={Partnerships} {...props} />;

const PrivacyBundle = lazy(() => import("../containers/PrivacyBundle/PrivacyContainerBundle"));
const LazyPrivacyBundle = (props) => <LazyLoad component={PrivacyBundle} {...props} />;

const NotFound = lazy(() => import("../containers/NotFound/NotFoundContainer"));
const LazyNotFound = (props) => <LazyLoad component={NotFound} {...props} />;

const DebitSuccess = lazy(() => import("../containers/Debit/Success/SuccessContainer"));
const LazyDebitSuccess = (props) => <LazyLoad component={DebitSuccess} {...props} />;

const Notifications = lazy(() => import("../containers/Notifications/NotificationsContainer"));
const LazyNotifications = (props) => <LazyLoad component={Notifications} {...props} />;

const cookieFirebase = cookie.load('HDeviceToken');

export default function getRoutes() {
  return (
    <>
      <Switch>
        <Route
          path="/"
          exact={true}
          component={LazyHomeBundle}
        /> :
        <Route
          path="/login"
          exact={true}
          component={LazyLogin}
        />
        <Route
          path="/registration"
          exact={true}
          component={LazyRegistration}
        />
        <Route
          path="/phone-verification"
          exact={true}
          component={LazyPhoneVerification}
        />
        <Route
          path="/reset-password/:token"
          exact={true}
          component={LazyResetPassword}
        />
        <Route
          path="/forgot-verification"
          exact={true}
          component={LazyForgotVerification}
        />
        <Route
          path="/forgot-password"
          exact={true}
          component={LazyForgotPassword}
        />
        <Route
          path="/confirmation/:token"
          exact={true}
          component={LazyEmailVerification}
        />
        <LoginRedirectRoute
          path="/account"
          exact={true}
          component={LazyAccount}
        />
        <LoginRedirectRoute
          path="/account/orders"
          exact={true}
          component={LazyAccountOrders}
        />
        <LoginRedirectRoute
          path="/account/orders/:id"
          exact={true}
          component={LazyAccountOrderDetails}
        />
        <LoginRedirectRoute
          path="/account/orders/canceling/:id"
          exact={true}
          component={LazyAccountOrderCanceling}
        />
        <LoginRedirectRoute
          path="/debit-processing"
          exact={true}
          component={LazyDebitSuccess}
        />
        <Route
          path="/contact"
          exact={true}
          component={LazyContactUsBundle}
        />
        <Route
          path="/cart"
          exact={true}
          component={LazyCart}
        />
        <Route
          path="/shop"
          exact={true}
          component={LazyShopBundle}
        />
        <Route
          path="/checkout"
          exact={true}
          component={LazyCheckout}
        />
        <Route
          path="/success"
          exact={true}
          component={LazySuccess}
        />
        <Route
          path="/partnerships"
          exact={true}
          component={LazyPartnerships}
        />
        <Route
          path="/terms"
          exact={true}
          component={LazyTermsConditionsBundle}
        />
        <Route
          path="/privacy"
          exact={true}
          component={LazyPrivacyBundle}
        />
        {cookieFirebase &&
          <Route
              path="/notifications"
              exact={true}
              component={LazyNotifications}
          />
        }
        <Route
          path="/:brand/:id"
          exact={true}
          component={LazyBundlePage}
        />
        <Route component={LazyNotFound} />
      </Switch>
    </>
  );
}

/**
|--------------------------------------------------
| Private Route Function
|--------------------------------------------------
*/
export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

/* export const RedirectRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("initial") && localStorage.getItem(`guId_${storeDomain}`) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{pathname: "/", state: { from: props.location, needVerification: true } }}
        />
      )
    }
  />
); */

export const LoginRedirectRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("initial") && localStorage.getItem(`guId_${storeDomain}`) && (localStorage.getItem("LoginStatus") === "true") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ 
            pathname: "/login", 
            state: {
              from: props.location
            },
          }}
        />
      )
    }
  />
);
