import rebudTheme from '../../styles/themes/DefaultTheme';
import bundleTheme from "../../styles/themes/BundleTheme";

const configRebud = {
    contactUsConfig: {
        background:'',
        colorFonts:'',
        classMainFont: '',
        classNameTitleFont: '',
        classNameDescription: '',
        color: 'brandDark',
        pstText: 'PST',
        mainPageHeadClass1: 't-section-title',
        mainPageHeadClass2: 't-link',
        mainPageHeadClass3: '',
        mainPageHeadClass4: '',
        contactFormFont: '',
        contactFormClass: 't-section-heading',
        buttonFont: '',
        buttonColor: "",
    },
    checkoutConfig: {
        headingClass: 't-section-heading',
        classNameFont: '',
        fontName1:  '',
        iconName1:  'checked',
        iconName2:  'check',
        steps: {
               font1: '',
               font2: 't-subtitle-large',
               font3: '',
               font4: '',
               font5: '',
               font6: '',
               font7: '',
               color1: rebudTheme.colors.gray2,
               color2: '',
               color3: rebudTheme.colors.brandDark,
               color4: rebudTheme.colors.gray3,
               classNameFont1: '',
        },
        simpleModal: {
            color1: rebudTheme.colors.white,
            color2: ''
        },
        mainFont1: 't-title-small',
        mainFont2: '',
        mainFont3: '',
        mainFont4: '',
        iconName3: 'delivery-circle-active',
        mainFont5: '',
        iconName4: 'delivery-normal-box',
        mainFont6: '',
        dark: true,
        mainFont7: '',
        mainFont8: '',
        mainFont9: 't-font-lato-regular',
        mainText1: 'Rebud',
        inputAuth1: {
            font: '',
            color1: '#f5f5f5',
            color2: rebudTheme.colors.gray5,
            color3: rebudTheme.colors.gray2,
            color4: rebudTheme.colors.white,
            color5: rebudTheme.colors.brandDark,
            color6: rebudTheme.colors.gray1,
            color7: rebudTheme.colors.gray3,
            color8: rebudTheme.colors.brandDark,
            font1: '',
            error: rebudTheme.colors.error,
            color9: rebudTheme.colors.gray3,
            color10: "#fff",
            color11: rebudTheme.colors.gray3,
        },
        dropUpload: {
            color1: '',
            color2: rebudTheme.colors.gray1,
            color3: '',
            color4: rebudTheme.colors.webGrey4,
            iconName1: 'upload-icon',
            error: rebudTheme.colors.webGrey4,
            font: "",
            errorText: rebudTheme.colors.newErrorForm  ,
        },
        themeLocationSearchInput: {
            font1: 't-form-input',
            font2: '',
            font3: '',
            zipColor1: rebudTheme.colors.white,
            zipColor2: rebudTheme.colors.gray3,
            padding: '1.375rem 16px',
            height1: '64px',
            height2: '64px',
            borderRadius: '4px',
            border: `solid 1px ${rebudTheme.colors.gray2}`,
            borderBottom: ``,
            font4: '',
            color1: '#ffffff',
            color2: ''
        },
        iconName5: 'store-pickup',
        checkoutStyles: {
            color1: '',
            font1: '',
            color2: '',
            color3: rebudTheme.colors.gray4,
            color4: rebudTheme.colors.brandDark,
            color5: rebudTheme.colors.white,
            color6: rebudTheme.colors.brand,
            color7: rebudTheme.colors.white,
            color8: '',
            color9: '',
            color10: '',
            color11: rebudTheme.colors.gray5,
            color12: rebudTheme.colors.gray5,
            color13: rebudTheme.colors.gray2,
            color14: rebudTheme.colors.gray1,
            color15: rebudTheme.colors.webGreen,
            color16: rebudTheme.colors.white,
            color17: rebudTheme.colors.black,
            color18: rebudTheme.colors.brandDark,
            color19: '',
            ontSize1: '',
            color20:  rebudTheme.colors.gray3,
            color21: rebudTheme.colors.brandDark,
            color22: '',
            color23: rebudTheme.colors.brandDark,
            color24: rebudTheme.colors.brand,
            color25: '',
            color26: rebudTheme.colors.gray3,
            color27: rebudTheme.colors.gray4,
            color28: '',
            color29: rebudTheme.colors.gray1,
            color30: rebudTheme.colors.gray2,
            color31: rebudTheme.colors.black,
            color32: rebudTheme.colors.white,
            margin: '0 auto',
            overflow: 'initial',
            color33: '#757575',
            color34: rebudTheme.colors.brandDark,
            error: rebudTheme.colors.error,
            maxWidth: 'initial',
            font2: '',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: ''
        },
        summaryConfig: {
            font1: '',
            font2: '',
            font3: '',
            font4: '',
        },
        priceItemColor: '#222'

    }
}
export default configRebud;