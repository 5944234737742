import styled from "styled-components";

const StyledModalVerification = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.modalBackground};
  transform : ${props => props.isShown ? 'translateY(0)' : 'translateY(-100vh)'};
  opacity : ${props => props.isShown ? '1' : '0'};
  z-index: 10;
  filter: blur(0) !important;

  .verification {
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 724px;
    min-height: 250px;
    background-color: ${({theme}) => theme.colors.white};
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    z-index: 12;
    
    @media (max-width: 799px) {
      padding: 2.5rem 16px 1.8125rem;
      max-width: 360px;
    }
    @media (min-width: 800px) {
      padding: 3rem 16px 3.625rem;

    }
  }
  
  .verification__logo {
    align-self: center;
    
    @media (max-width: 799px) {
      margin-bottom: 1.5rem;
      width: 86px;
      height: 40px;
    }
    @media (min-width: 800px) {
      margin-bottom: 4.25rem;
      width: 120px;
      height: 60px;
    }
  }

  .verification__heading {
    color: ${({theme}) => theme.colors.brandDark};

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 1rem;
    }
  }
  
  .verification__age {
    color: ${({theme}) => theme.colors.brandDark};
    text-align: center;
    padding-bottom: 6rem;
    
    &.is-disabled {
      color: ${({theme}) => theme.colors.gray3};
    }
  }
  
  // Button / Link overwrite, change with future button and link design cleanup
  .verification__button,
  .verification__age__button {
    @media (max-width: 799px) {
      width: 100%;
    }
    @media (min-width: 800px) {
      width: 144px;
    }
  }

  // Button / Link overwrite, change with future button and link design cleanup
  .verification__button {
    padding: 14px 31px;
    line-height: normal;
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.colors.brandDark};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.brandDark};
    font-size: var(--type-scale-16);
    font-weight: var(--weight-bold);
    text-align: center;
    flex-shrink: 0;
    transition: var(--button-transition);
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.colors.brand};
      border-color: ${({ theme }) => theme.colors.brand};
      color: ${({ theme }) => theme.colors.white};
    }
    
    &.is-disabled {
      background-color: ${({ theme }) => theme.colors.white};
      border-color: ${({ theme }) => theme.colors.gray3};
      color: ${({ theme }) => theme.colors.gray3};
      cursor: default;
    }

    @media (max-width: 799px) {
      margin-top: .5rem;
    }
    @media (min-width: 800px) {
      margin-left: 16px;
    }
  }

  .verification__form {
    display: flex;
    flex-direction: column;
    max-width: 512px;
    width: 100%;

    @media (max-width: 799px) {
      margin-bottom: 1.5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 2rem;
    }
  }

  .verification__form__label {
    width: 100%;  
    position: relative;
    color: ${({theme}) => theme.colors.brandDark};
  }
  
  .verification__form__age-heading {
    margin-bottom: .75rem;
    color: inherit;

    @media (max-width: 799px) {
      margin-bottom: .5rem;
    }
    @media (min-width: 800px) {
      margin-bottom: .75rem;
    }
  }
  
  .verification__form__label-text {
    margin-bottom: .75rem;
  }

  .verification__form__age-copy {
    color: inherit;

    @media (max-width: 799px) {
      margin-bottom: 1rem;
    }
    @media (min-width: 800px) {
      margin-bottom: 1.25rem;
    }
  }

  .verification__footnote {
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.brandDark};

    .t-link {
      color: inherit;
    }
    .t-link:hover,
    .t-link:focus {
      color: ${({theme}) => theme.colors.brand};
    }
  }
  .not_deliv{
    margin: 3px 0;
    font-size: 12px;
  }

  /* Temporary until v2 design */
  .verification__input {
    background-color: transparent;
    border-radius: 0;
    transition: var(--button-transition);
    padding-right: 40px;

    &:hover,
    &:focus {
      border-color: ${({theme}) => theme.colors.brand};
    }
  }

  .verification__form__address-input {
    position: relative;
  }
  .verification__form__ip-address-input {
    margin-top: 15px;
    .placeHold{
      display: none;
    }
    .verification__form__label-text{
      color: #183f31;
    }
    input{
      border-radius: 0;
    }
  }
  .verification-delivery_address__clear {
    border: none;
    outline: none;
    right: 14px;
    top: 1.625rem;
    width: 16px;
    height: 16px;
    position: absolute;
    align-self: center;

    svg {
      fill: ${({theme}) => theme.colors.gray3};
      width: inherit;
      height: inherit;

    }

    &:hover,
    &:focus {
      svg {
        fill: ${({theme}) => theme.colors.brandDark};
      }
    }
  }
`;

export default StyledModalVerification;
