import bundleTheme from '../../styles/themes/BundleTheme';
import rebudTheme from "../../styles/themes/DefaultTheme";

const configBundle = {
    contactUsConfig: {
        background: bundleTheme.colors.bundleBlack,
        colorFonts: bundleTheme.colors.bundleWhite,
        classMainFont: 't-font-DINNextLTPro-Regular',
        classNameTitleFont: 't-font-DINNextLTPro-Regular',
        classNameDescription: 't-font-DINNextLTPro-Regular',
        color: 'bundleBlack',
        pstText: 'PST (toll free)',
        mainPageHeadClass1: 't-section-title',
        mainPageHeadClass2: 't-link',
        mainPageHeadClass3: '',
        mainPageHeadClass4: '',
        contactFormFont: 'DINNextLTPro-Regular, sans-serif',
        contactFormClass: 't-section-heading t-font-DINNextLTPro-Regular',
        buttonFont: 'DINNextLTPro-Regular, sans-serif',
        buttonColor: "#181818",
    },
    checkoutConfig: {
        headingClass: 't-section-heading t-font-DINNextLTPro-Regular',
        classNameFont1: 't-font-DINNextLTPro-Regular',
        fontName1:  '',
        iconName1:  'checked-bundle',
        iconName2:  'check-bundle',
        steps: {
            font1: '',
            font2: 't-subtitle-large t-font-DINNextLTPro-Regular',
            font3: '',
            font4: '',
            font5: '',
            font6: '',
            font7: '',
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.bundleGrey,
            color3: bundleTheme.colors.bundleBlack,
            color4: bundleTheme.colors.bundleGrey,
            classNameFont1: 't-font-DINNextLTPro-Regular',
        },
        simpleModal: {
            color1: bundleTheme.colors.gray4,
            color2: bundleTheme.colors.bundleBlack
        },
        mainFont1: 't-title-small',
        mainFont2: '',
        mainFont3: '',
        mainFont4: '',
        iconName3: 'delivery-circle-active-bundle',
        mainFont5: '',
        iconName4: 'delivery-normal-box-bundle',
        mainFont6: '',
        dark: true,
        mainFont7: '',
        mainFont8: '',
        mainFont9: 't-font-lato-regular',
        mainText1: 'Bundle',
        inputAuth1: {
            font: 't-font-DINNextLTPro-Regular',
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.bundleWhite,
            color3: bundleTheme.colors.bundleGrey,
            color4: bundleTheme.colors.bundleWhite,
            color5: bundleTheme.colors.bundleWhite,
            color6: bundleTheme.colors.bundleWhite,
            color7: bundleTheme.colors.bundleBlack,
            color8: bundleTheme.colors.bundleBlack,
            error:  bundleTheme.colors.error,
            color9: bundleTheme.colors.bundleGrey,
            color10: bundleTheme.colors.bundleWhite,
            color11: bundleTheme.colors.bundleBlack,

        },
        dropUpload: {
            color1: bundleTheme.colors.bundleGrey,
            color2: bundleTheme.colors.bundleGrey,
            color3: bundleTheme.colors.bundleBlack,
            color4: bundleTheme.colors.bundleGrey3,
            iconName1: 'img-upload-bundle',
            error: bundleTheme.colors.error,
            font: "",
            errorText: rebudTheme.colors.gray4,
        },
        themeLocationSearchInput: {
            font1: 't-font-DINNextLTPro-Regular',
            font2: '',
            font3: '',
            zipColor1: bundleTheme.colors.bundleWhite,
            zipColor2: bundleTheme.colors.bundleGrey2,
            padding: '1.375rem 16px',
            height1: '64px',
            height2: '64px',
            borderRadius: '4px',
            border: `solid 1px ${bundleTheme.colors.gray2}`,
            borderBottom: ``,
            font4: '',
            color1: bundleTheme.colors.bundleWhite,
            color2: bundleTheme.colors.bundleBlack
        },
        iconName5: 'store-pickup-bundle',
        checkoutStyles: {
            color1:  bundleTheme.colors.bundleBlack,
            font1:  'DINNextLTPro-Regular, sans-serif',
            color2:  bundleTheme.colors.bundleWhite,
            color3:  bundleTheme.colors.bundleGrey,
            color4:  bundleTheme.colors.bundleBlack,
            color5:  bundleTheme.colors.bundleGrey,
            color6:  bundleTheme.colors.bundleWhite,
            color7:  bundleTheme.colors.bundleBlack,
            color8:  bundleTheme.colors.white,
            color9:  bundleTheme.colors.gray3,
            color10: bundleTheme.colors.bundleWhite,
            color11: bundleTheme.colors.bundleGrey,
            color12: bundleTheme.colors.bundleWhite,
            color13: bundleTheme.colors.bundleGrey,
            color14: bundleTheme.colors.bundleWhite,
            color15: bundleTheme.colors.bundleWhite,
            color16: bundleTheme.colors.bundleWhite,
            color17: bundleTheme.colors.bundleWhite,
            color18: bundleTheme.colors.bundleGrey,
            color19: bundleTheme.colors.bundleGrey,
            fontSize1: '16px',
            color20: bundleTheme.colors.bundleBlack,
            color21: bundleTheme.colors.bundleGrey3,
            color22: bundleTheme.colors.bundleGrey3,
            color23: bundleTheme.colors.bundleGrey2,
            color24: bundleTheme.colors.bundleGrey2,
            color25: bundleTheme.colors.bundleGrey4,
            color26: bundleTheme.colors.brandDark,
            color27: bundleTheme.colors.brandDark,
            color28: bundleTheme.colors.gray4,
            color29: bundleTheme.colors.gray4,
            color30: bundleTheme.colors.bundleGrey3,
            color31: bundleTheme.colors.bundleBlack,
            color32: bundleTheme.colors.gray4,
            margin: '20px auto',
            overflow: 'scroll',
            color33: bundleTheme.colors.bundleGrey3,
            color34: bundleTheme.colors.bundleGrey,
            error: bundleTheme.colors.error,
            maxWidth: 'initial',
            font2: '',
        },
        spinner: {
            font1: '',
            font2: '',
            color1: bundleTheme.colors.bundleWhite
        },
        summaryConfig: {
            font1: '',
            font2: '',
            font3: '',
            font4: '',
        },
        priceItemColor: bundleTheme.colors.bundleWhite
    }
}
export default configBundle;