import React from "react";
import styled from "styled-components";

const StyledInputZip = styled.input`
  width: 100%;
  height: 36px;
  border: none;
  border-bottom: 1px solid ${({theme}) => theme.colors.flowerBrandDark};
  background-color: ${({ theme }) => theme.colors.white};
  transition: var(--button-transition);
  outline: none;
  font-family: var(--font-Moderat-Mono);

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray3}
  }

  &:hover,
  &:focus {
    border-color: ${({ theme }) => theme.colors.brand}
  }
  &.disabled {
    border: solid 1px ${({ theme }) => theme.colors.gray1};
    background-color: ${({ theme }) => theme.colors.gray1};
  }

  @media (max-width: 799px) {
      height: 26px;
    }
`;

export default React.memo(props => <StyledInputZip {...props} />);