import React, {useRef} from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import InputZipEverscore from "../forms/InputZipEverscore";
import StyledPlacesContainer from "./LocationSearchInput.styled";
import StyledLocationWarningCopy from "./LocationWarningCopy.styled";
import InputZip from "../forms/InputZip";

const LocationSearchInputFlower = React.memo(props => {
    const { address, handleChange, handleSelect, suggestionData, disabled, notDelivery, required, placeholder, enterZip, streetSearch, locationSearch, className, errorMessage, setIsAddress, onBlur, handleWrongAddress } = props;
    const myRef = useRef();

    const onSelectAddress = address => {
        geocodeByAddress(address)
            .then(results => {
                setIsAddress && setIsAddress(true);
                handleSelect(results[0].formatted_address)
            })
            .catch(() => {
                setIsAddress && setIsAddress(false)
                handleWrongAddress && handleWrongAddress()
            } );
    };

    return (
        <PlacesAutocomplete
            searchOptions={{
                types: streetSearch ? ['address'] : [],
                componentRestrictions: { country: "us"} ,
                location: new window.google.maps.LatLng(36.778259,-119.417931),
                radius: 762000
            }}
            value={address}
            onChange={address=> {
                const re =/^[\w-_.#&',/ áéíóúüñ\u2014\u2013]*$/;
                if (address === '' || re.test(address)) {
                    handleChange(address)
                }
                setIsAddress && setIsAddress(false);
            }}
            onSelect={onSelectAddress}
            ref={myRef}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                <>
                    <InputZipEverscore
                        {...getInputProps({
                            placeholder: `${placeholder ? placeholder : "Enter Your address" }`,
                            name: "address",
                            className: `location-search-input t-form-input ${disabled ? "disabled" : ""} ${className}`,
                            required: required,
                            onBlur  : onBlur,
                            disabled
                        })}
                    />
                    <StyledPlacesContainer
                        className={`autocomplete-dropdown-container ${
                            suggestions.length > 0 ? "active" : ""
                        }`}
                    >
                        {loading && <div>Loading...</div>}
                        {(suggestionData? suggestionData : suggestions).map(suggestion => {
                            const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";

                            return (
                                <div
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion, {
                                        className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </StyledPlacesContainer>
                    {notDelivery && <StyledLocationWarningCopy className="t-body-small">Delivery isn’t available in your location, but you can view the shop.</StyledLocationWarningCopy>}
                    {errorMessage && <StyledLocationWarningCopy className="t-body-small">Delivery isn’t available in this location</StyledLocationWarningCopy>}
                    {locationSearch && <StyledLocationWarningCopy className="t-body-small">Please select a full shipping address.</StyledLocationWarningCopy>}
                </>
            )}
        </PlacesAutocomplete>
    );
});

LocationSearchInputFlower.propTypes = {
    address: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    handleSelect: PropTypes.func.isRequired,
    notDelivery: PropTypes.bool,
    className: PropTypes.string,
    placeholder: PropTypes.string
};
LocationSearchInputFlower.defaultProps = {
    disabled: false
};
export default LocationSearchInputFlower;
