/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cookie from "react-cookies";

// Local Imports
import { locationActions, globals, categoriesFunc } from '../../actions';
import { storeType, storeName, isKiosk } from "../../config/constants";
import { history } from "../../helpers";
import { locationPhonePT, featuredBrandPT, categoriesPT, discoversAllPT } from "../../config/prop-types"

// Component Imports
import StyledHeaderMainBundle from "./HeaderMainBundle.styled";
import InputSearchAheadBundle from "../InputSearchAhead/InputSearchAheadBundle/InputSearchAheadBundle";
import MobileMenuBundle from "../MobileMenuBundle/MobileMenuBundle";
import SVGIcon from "../utility/SVGIcon";
import ProfileDropdownBundle from "../ProfileDropdown/ProfileDropdownBundle/ProfileDropdownBundle";
import MainMenu from "../MainMenu/MainMenu";
import HeaderMainSecondaryBundle from "../HeaderMainSecondaryBundle/HeaderMainSecondaryBundle";

const HeaderMainBundle = React.memo(props => {
    const {
        brandName,
        brandLogo,
        dispatch,
        cartCount,
        featuredBrand,
        categories,
        personality,
        sessionClose,
        discoversAll,
    } = props;

    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        dispatch(locationActions.selectedLocation());
        dispatch(locationActions.locationInfo());
        dispatch(globals.cartCount());
        dispatch(categoriesFunc.featuredBrandsAll());
    }, []);

    useEffect(()=>{
        if (localStorage.getItem("token")) {
            dispatch(globals.userInfo());
        }
    }, [])

    // Look up current route
    const currentRoute = useLocation();
    const formatPathName = (str) => {
        //Replace all "/" from pathname with space
        let lettersOnly = ('' + str).replace(/[/]/g, ' ');
        // Uppercase first letter of string
        let formatPath = lettersOnly.charAt(1).toUpperCase() + lettersOnly.slice(2);

        return formatPath;
    };
    const LoginStatus = JSON.parse(localStorage.getItem('LoginStatus'));
    return (
        <StyledHeaderMainBundle>
            <div className="header-main">
                <div className="header-main__wrap">
                    <MediaQuery maxWidth={859}>
                        <MobileMenuBundle
                          categories={categories}
                          featuredBrand={featuredBrand}
                          personality={personality}
                          discoversAll={discoversAll}
                          dispatch={dispatch}
                        />
                    </MediaQuery>

                    <h1 className="t-hidden">
                      {brandName} Online Cannabis Delivery
                      {currentRoute.pathname === "/" ?
                          ""
                          :
                          ` - ${formatPathName(currentRoute.pathname)}`
                      }
                    </h1>

                    <Link to="/" className="header-main__home">
                        {storeType === 'rebud' ?
                            <>
                              <SVGIcon name="bundle-icon" className="header-main__logo" />
                            </>
                            :
                            <img src={brandLogo} alt={brandName} className="header-main__logo-other" />
                        }
                        <span className="t-hidden">{storeName} home</span>
                    </Link>

                    <div className="t-header-link title" onClick={()=>{history.push("/shop")}}>SHOP BUNDLES</div>
  
                    <div className="header-main__icons">
                        <InputSearchAheadBundle
                            inputName="search_input"
                            placeholder="What are you looking for…"
                            className="header-main__search-ahead"
                        />

                        <div className="header-main__user">
                            <ProfileDropdownBundle className="header-main__user__button" dispatch={dispatch} />
                        </div>
                        {cookie.load('HDeviceToken') && LoginStatus &&
                            <Link to="/notifications" className="header-main__icon-button notification-icon">
                                <SVGIcon name="notifications" />
                            </Link>
                        }

                        <Link to="/cart" className="header-main__icon-button cart-icon">
                            CART
                            <span className="t-hidden">Cart</span>
                            <div className="cart-count">{cartCount ? cartCount : localStorage.getItem("itemsCountInShoppingCart")}</div>
                        </Link>
                        {isKiosk && LoginStatus &&
                            <button data-testid="close-session" onClick={sessionClose} className="header-main__icon-button close-session">
                                <SVGIcon name="close_session" />
                            </button>
                        }
                    </div>

                    <MainMenu
                        isOpen={menuOpen}
                        setMenuOpen={setMenuOpen}
                        categories={categories}
                        featuredBrand={featuredBrand}
                        personality={personality}
                        discoversAll={discoversAll}
                    />
                </div>
            </div>

            {!isKiosk && <HeaderMainSecondaryBundle />}
        </StyledHeaderMainBundle>
    );
});
const mapStateToProps = ({ userAddress, locationInfo, cartCount, featuredBrandAll, categoriesAll, personalityAll, discoversAll, }) => ({
    address: userAddress.address,
    locationPhone: locationInfo.locationPhone,
    cartCount: cartCount.count,
    featuredBrand: featuredBrandAll.featuredBrandsListAll,
    categories: categoriesAll.catListAll,
    personality: personalityAll.personalityListAll,
    discoversAll: discoversAll.discoversAll,
});

HeaderMainBundle.propTypes = {
    brandLogo: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    locationPhone: locationPhonePT,
    cartCount: PropTypes.number,
    featuredBrand: featuredBrandPT,
    categories: categoriesPT,
    sessionClose: PropTypes.func.isRequired,
    discoversAll: discoversAllPT,
};

export default connect(mapStateToProps)(HeaderMainBundle);